<template>
<nav>
  <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
    <span>Agregaste un Proyecto!.</span>
    <v-btn color="white" flat @click="snackbar = false">Close</v-btn>
  </v-snackbar>
  <v-toolbar app height=50>
    <v-toolbar-title class="grey--text">
      <span>Soluciones Integrales</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn flat color="grey" @click="salir">
      <span>Salir</span>
      <v-icon right>exit_to_app</v-icon>
    </v-btn>
  </v-toolbar>
  <v-navigation-drawer app width=250 :mini-variant="mini" class="primary">
    <v-layout column align-center>
      <v-flex class="mt-2">
        <v-img :src="require('@/assets/logo_nav.png')" class="logo" alt="FM Soluciones"></v-img>
      </v-flex>
      <v-flex>
        <v-list>
          <v-list-tile v-if="!mini">
            <v-list-tile-action>
              <v-btn icon @click.stop="mini = !mini">
                <v-icon>chevron_left</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
          <v-list-tile v-if="mini">
            <v-list-tile-action>
              <v-btn icon @click.stop="mini = !mini">
                <v-icon>chevron_right</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
          <v-list-tile v-for="link in links" :key="link.text" router :to="link.route">
            <v-list-tile-action>
              <v-icon class="white--text">{{ link.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title class="white--text">{{ link.text }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-flex>
    </v-layout>
  </v-navigation-drawer>
</nav>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      drawer: false,
      mini: true,
      links: [
        { icon: 'folder', text: 'Proyectos', route: '/manager/proyectos' },
        { icon: 'folder', text: 'Proyectos 2020', route: '/manager/proyectos-2020' },
        { icon: 'folder', text: 'Proyectos 2021', route: '/manager/proyectos-2021' },
        { icon: 'folder', text: 'Casos Activos', route: '/manager/activos' },
        { icon: 'insert_chart', text: 'Estadisticas', route: '/manager/estadisticas' }
      ],
      snackbar: false
    }
  },
  methods: {
    ...mapActions('account', {
      salir: 'logout'
    })
  },
  beforeCreate () {
    document.title = 'FM Soluciones | Manager'
  }
}
</script>

<style>
.logo{
  height: auto;
  width: 70px;
}
</style>
