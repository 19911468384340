import { userService } from '../_services'
import { router } from '../_helpers'

const user = JSON.parse(localStorage.getItem('user'))
const state = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null }

const actions = {
  login ({ dispatch, commit }, { email, password }) {
    commit('loginRequest', { email })

    userService.login(email, password)
      .then(
        user => {
          commit('loginSuccess', user)
          // Chequeo de rol
          if (user.role === 'Administrador') {
            router.push('admin/proyectos')
          } else if (user.role === 'Manager') {
            router.push('manager/proyectos')
          } else {
            router.push('sucursal/proyectos')
          }
        },
        error => {
          commit('loginFailure', error)
          dispatch('alerts/error', { message: error }, { root: true })
        }
      )
  },
  logout ({ commit }) {
    userService.logout()
    commit('logout')
    router.push('/')
  }
}

const mutations = {
  loginRequest (state, user) {
    state.status = { loggingIn: true }
    state.user = user
  },
  loginSuccess (state, user) {
    state.status = { loggedIn: true }
    state.user = user
  },
  loginFailure (state) {
    state.status = {}
    state.user = null
  },
  logout (state) {
    state.status = {}
    state.user = null
  }
}

export const account = {
  namespaced: true,
  state,
  actions,
  mutations
}
