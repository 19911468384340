<template>
  <div>
    <Navbar/>
    <router-view/>
  </div>
</template>

<script>
import Navbar from '@/views/components/manager/Navbar.vue'

export default {
  name: 'manager',
  components: {
    Navbar
  },
  beforeCreate () {
    document.title = 'FM Soluciones | Manager'
  },
  created () {
    this.$store.dispatch('casos/cargarCasos')
  }
}
</script>

<style>
  .v-list__tile__action .v-icon {
    cursor: pointer;
  }
</style>
