import { eventosService } from '../_services'

const state = {
  all: []
}

const actions = {

  cargarEventos ({ commit }) {
    commit('getAllRequest')

    eventosService.getAll()
      .then(
        result => commit('getAllSuccess', result.data),
        error => commit('getAllFailure', error)
      )
  },

  creaEvento ({ dispatch, commit }, evento) {
    commit('registerRequest', evento)

    eventosService.register(evento)
      .then(
        eventox => {
          commit('registerSuccess', eventox)
          setTimeout(() => {
            // display success message after route change completes
            dispatch('alerts/success', { message: 'Evento Guardado' }, { root: true })
          })
        },
        error => {
          commit('registerFailure', error)
          dispatch('alerts/error', { message: error.response.data.message }, { root: true })
        }
      )
  },

  dilitEvento ({ commit }, id) {
    commit('deleteRequest', id)

    eventosService.delete(id)
      .then(
        evento => commit('deleteSuccess', id),
        error => commit('deleteFailure', { id, error: error.toString() })
      )
  },

  updeitEvento ({ commit }, evento) {
    commit('updateRequest', evento.id)
    eventosService.update(evento)
      .then(
        evento => commit('updateSuccess', evento),
        error => commit('updateFailure', { evento, error: error.toString() })
      )
  }
}

const mutations = {
  getAllRequest (state) {
    state.all = { loading: true }
  },
  getAllSuccess (state, eventos) {
    state.all = { items: eventos }
  },
  getAllFailure (state, error) {
    state.all = { error }
  },
  registerRequest (state, evento) {
    state.status = { registering: true }
  },
  registerSuccess (state, evento) {
    state.all.items.push(evento)
  },
  registerFailure (state, error) {
    state.status = {}
  },
  deleteRequest (state, id) {
    // add 'deleting:true' property to evento being deleted
    state.all.items = state.all.items.map(evento =>
      evento.id === id
        ? { ...evento, deleting: true }
        : evento
    )
  },
  deleteSuccess (state, id) {
    // remove deleted evento from state
    state.all.items = state.all.items.filter(evento => evento.id !== id)
  },
  deleteFailure (state, { id, error }) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to evento
    state.all.items = state.all.items.map(evento => {
      if (evento.id === id) {
        // make copy of evento without 'deleting:true' property
        const { deleting, ...eventoCopy } = evento
        // return copy of evento with 'deleteError:[error]' property
        return { ...eventoCopy, deleteError: error }
      }

      return evento
    })
    console.log(error)
  },
  updateRequest (state, id) {
    // add 'updating:true' property to evento being updated
    state.all.items = state.all.items.map(evento =>
      evento.id === id
        ? { ...evento, updating: true }
        : evento
    )
  },
  updateSuccess (state, userx) {
    // update evento in state
    const item = state.all.items.find(evento => evento.id === userx.id)
    Object.assign(item, userx)
  },
  updateFailure (state, { id, error }) {
    // remove 'updating:true' property and add 'updateError:[error]' property to evento
    state.all.items = state.items.map(evento => {
      if (evento.id === id) {
        // make copy of evento without 'updating:true' property
        const { updating, ...eventoCopy } = evento
        // return copy of evento with 'updateError:[error]' property
        return { ...eventoCopy, updateError: error }
      }

      return evento
    })
  }
}
const getters = {
  /* getEvents (state) {
    return state.all.items.filter(function (el) {
      return el.estado === 'En Inspección' || el.estado === 'En Ejecución' || el.estado === 'Asignado' || el.estado === 'Esperando Aprobación'
    })
  },
  valpo (state) {
    return state.all.items.filter(function (el) {
      return el.sucursal === 'Valparaiso'
    })
  },
  lastYear (state) {
    return state.all.items.filter(function (el) {
      var date = new Date().getFullYear()
      date = date - 1
      if (el.fechaAsignacion !== undefined || null) {
        if (el.fechaAsignacion.substring(0, 4) === date) {
          return el
        }
      }
    })
  },
  stadistics (state) {
    return state.all.items
  } */
}
export const eventos = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
