<template>
<nav>
  <v-toolbar app height=50>
    <v-toolbar-side-icon @click="drawer = !drawer" class="grey--text hidden-xl-and-up"></v-toolbar-side-icon>
    <v-toolbar-title class="grey--text">
      <span>Soluciones Integrales</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn flat color="grey" @click="salir">
      <span>Salir</span>
      <v-icon right>exit_to_app</v-icon>
    </v-btn>
  </v-toolbar>
  <v-navigation-drawer app width=250 :mini-variant="mini" v-model="drawer" class="primary">
    <v-layout column align-center>
      <v-flex class="mt-2">
        <v-img :src="require('@/assets/logo_nav.png')" class="logo" alt="FM Soluciones"></v-img>
      </v-flex>
      <v-flex>
        <v-list>
          <v-list-tile v-if="!mini">
            <v-list-tile-action>
              <v-btn icon @click.stop="mini = !mini">
                <v-icon>chevron_left</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
          <v-list-tile v-if="mini">
            <v-list-tile-action>
              <v-btn icon @click.stop="mini = !mini">
                <v-icon>chevron_right</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
          <v-list-tile v-for="link in links" :key="link.text" router :to="link.route">
            <v-list-tile-action>
              <v-icon class="white--text">{{ link.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title class="white--text">{{ link.text }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-flex>
    </v-layout>
  </v-navigation-drawer>
</nav>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      drawer: true,
      mini: true,
      links: [
        { icon: 'source', text: 'Proyectos', route: '/admin/proyectos' },
        { icon: 'folder', text: 'Proyectos 2020', route: '/admin/proyectos-2020' },
        { icon: 'folder', text: 'Proyectos 2021', route: '/admin/proyectos-2021' },
        { icon: 'folder', text: 'Casos Abiertos', route: '/admin/activos' },
        { icon: 'event', text: 'Agenda', route: '/admin/agenda' },
        { icon: 'insert_chart', text: 'Estadisticas', route: '/admin/estadisticas' },
        // { icon: 'group', text: 'Maestros', route: '/admin/maestros' },
        // { icon: 'fa-file-pdf-o', text: 'Informes', route: '/admin/informes' },
        // { icon: 'comment', text: 'Encuesta', route: '/admin/encuesta' },
        { icon: 'person', text: 'Usuarios', route: '/admin/usuarios' }
      ]
    }
  },
  methods: {
    ...mapActions('account', {
      salir: 'logout'
    })
  },
  beforeCreate () {
    document.title = 'FM Soluciones | Administrador'
  }
}
</script>

<style>
.logo{
  height: auto;
  width: 70px;
}
</style>
