<template>
<v-content class="">
<Nab />
  <section>
  <v-container fluid class="layout justify-center">
  <form method="POST" @submit.prevent="contacto">
    <v-card max-width="600">
    <v-card-title primary-title>
      <div class="headline">Contacto</div>
    </v-card-title>
    <v-card-text>
      <v-container grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-text-field v-model="contact.name" label="Nombre" placeholder="Ej: Mario Muñoz" type="name" required></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field v-model="contact.email" label="Email" :rules="emailRules" placeholder="hola@micorreo.com" type="email" required></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field v-model="contact.telefono" label="Telefono" placeholder="Ej: +56999999999" type="phone" required></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-textarea v-model="contact.message" label="Mensaje" type="message" auto-grow required></v-textarea>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" type="submit" flat >Enviar</v-btn>
    </v-card-actions>
    </v-card>
  </form>
  </v-container>
  </section>
  <Futer />
  </v-content>
</template>
<script>
import Nab from '@/views/components/Nav.vue'
import Futer from '@/views/components/Footer.vue'
export default {
  name: 'contactanos',
  components: {
    Nab,
    Futer
  },
  data: () => ({
    defaultItem: {
      name: '',
      telefono: '',
      email: '',
      message: ''
    },
    contact: {
      name: '',
      telefono: '',
      email: '',
      message: ''
    },
    emailRules: [
      v => {
        return !!v || 'E-mail es requerido'
      },
      v =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        'E-mail debe ser valido'
    ]
  }),
  methods: {
    clearForm () {
      for (const field in this.contact) {
        this.contact[field] = ''
      }
    },
    close () {
      setTimeout(() => {
        this.contact = Object.assign({}, this.defaultItem)
      }, 100)
    },
    contacto () {
      this.$http.post(this.$cfg.base + '/contact', this.contact).then(
        response => {
          // this.clearForm()
          this.close()
          alert(response)
        },
        error => {
          alert(error.body.message)
        }
      )
    }
  }
}
</script>
