<template>
  <div class="vue-survey-builder-content vsb-content">
    <select class="vsb-select" v-model="selectedType" v-on:change="questionTypeChanged(selectedType)">
      <option v-for="(questionType, index) in questionTypes" :value="questionType.value" :key="index" :disabled="questionType.value === 'DEFAULT'" :selected="questionType.value === 'DEFAULT'">{{questionType.label}}</option>
    </select>
    <div class="question-section" v-if="selectedType !== 'DEFAULT'">
      <div class="pb-10">
        <div class="vsb-choices-text">Pregunta</div>
        <input type="text" class="" placeholder="Ingrese el texto de la pregunta" v-model="question.body">
      </div>
      <div class="" v-if="selectedType === 'BOOLEAN'">
        <div class="vsb-choices-text">Opciones de respuesta</div>
        <div class="" v-for="(option, index) in question.options" :key="index">
          <div class="clear-both">
            <input type="text" class="width-100 float-left" placeholder="Enter an answer choice" v-model="option.body">
            <button class="vsb-btn-link color-red width-10 mt-10" v-on:click="deleteQuestionOptionItem(question.options, index)" v-if="index > 1">Eliminar</button>
          </div>
        </div>
      </div>
      <div class="" v-if="selectedType === 'DATE'">
        <div class="">
          <label class="vsb-block"><input type="radio" v-model="question.dateFormat" value="MM/DD/YY"> MM/DD/YY</label>
          <label class="vsb-block"><input type="radio" v-model="question.dateFormat" value="DD/MM/YY"> DD/MM/YY</label>
          <label class="vsb-block"><input type="radio" v-model="question.dateFormat" value="MM/DD/YYYY"> MM/DD/YYYY</label>
          <label class="vsb-block"><input type="radio" v-model="question.dateFormat" value="DD/MM/YYYY"> DD/MM/YYYY</label>
        </div>
      </div>
      <div class="" v-if="selectedType === 'MULTI_CHOICE'">
        <div class="vsb-choices-text">Opciones de respuesta</div>
        <div class="" v-for="(option, index) in question.options" :key="index">
          <div class="clear-both">
            <input type="text" class="width-90 float-left" placeholder="Ingresa una opción de respuesta" v-model="option.body">
            <button class="vsb-btn-link color-red width-10 mt-10" v-on:click="deleteQuestionOptionItem(question.options, index)" v-if="index > 1">Eliminar</button>
          </div>
        </div>
        <div class="display-block">
          <button class="vsb-btn-link color-blue" v-on:click="addAnotherAnswer()">Agregar otra respuesta</button>
        </div>
      </div>

      <div class="" v-if="selectedType === 'NUMBER'">
        <label class="display-block">
          <input type="checkbox" class="" v-model="question.hasUnits" name="hasUnits" />
          <span class="">Etiqueta de respuesta <input type="text" class="width-10" placeholder="ex. mins, lbs, days" v-model="question.units" :disabled="!question.hasUnits"></span>
        </label>
        <label class="display-block">
          <input type="checkbox" v-model="question.hasMinMax" name="subType" />
          <span class="">Valor mínimo / máximo
            <input type="number" class="width-10" v-model="question.minValue" placeholder="min" min="1" max="2048" :disabled="!question.hasMinMax">
            <span class="width-10">to</span>
            <input type="number" class="width-10" v-model="question.maxValue" placeholder="max" min="1" max="2048" :disabled="!question.hasMinMax">
          </span>
        </label>
        <label class="display-block">
          <input type="checkbox" v-model="question.allowDecimals" value="Single" name="subType" />
          <span class="">Permitir decimales</span>
        </label>
      </div>
      <div class="scale-type" v-if="selectedType === 'SCALE'">
        <div>Etiquetas de escala</div>
        <div class="">
          <div class="display-inline-block">Intervalos</div>
          <div class="intervals display-inline-block">
            <input type="number" min="2" max="100" v-model="question.intervals" v-on:change="changeLabelsLength(question.intervals)">
          </div>
          <span class="" v-if="question.reportable">Se puede ingresar un máximo de 100 intervalos.</span>
        </div>
        <div v-if="question.intervals > 0">
          <div class="" v-for="(value, index) in question.labels" :key="index">
            <div v-if="index === 0">
              <div class="width-10 float-left pt-10">Top</div>
              <div class="width-90">
                <input type="text" class="" placeholder="Ingresar valor" v-model="question.labels[question.labels.length - index - 1]">
              </div>
            </div>
            <div v-else-if="question.labels && (index === question.labels.length - 1)">
              <div class="width-10 float-left pt-10">Abajo</div>
              <div class="width-90">
                <input type="text" name="vertical-labels" class="" placeholder="Ingresar valor" v-model="question.labels[question.labels.length - index - 1]">
              </div>
            </div>
            <div class="text-right" v-if="question.labels && index !== 0 && (index !== question.labels.length - 1)">
              <div class="width-90">
                <input type="text" class="" placeholder="Ingresar valor" v-model="question.labels[question.labels.length - index - 1]">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="" v-if="selectedType === 'SINGLE_CHOICE'">
        <div class="vsb-choices-text">Opciones de respuesta </div>
        <div class="" v-for="(option, index) in question.options" :key="index">
          <div class="clear-both">
            <input type="text" class="width-90 float-left" placeholder="Ingresa una opción de respuesta" v-model="option.body">
            <button class="vsb-btn-link color-red width-10 mt-10" v-on:click="deleteQuestionOptionItem(question.options, index)" v-if="index > 1">Remove</button>
          </div>
        </div>
        <div class="display-block">
          <button class="vsb-btn-link color-blue" v-on:click="addAnotherAnswer()">Add another answer</button>
        </div>
      </div>
      <div class="" v-if="selectedType === 'TEXT'">
        <label class="">
          <input type="checkbox" v-model="question.characterLimited" name="characterLimited" />
          <span class="">Character limit <input type="number" class="char-limit-input" v-model="question.textLimit" placeholder="" min="1" max="2048" :disabled="!question.characterLimited"></span>
        </label>
      </div>
      <div class="" v-if="selectedType === 'TIME'">
        <div class="">
          <label class=""><input type="radio" v-model="question.timeFormat" value="12" v-on:click="timeFormatModified(question.timeFormat)"> 12 hrs</label>
          <label class=""><input type="radio" v-model="question.timeFormat" value="24" v-on:click="timeFormatModified(question.timeFormat)"> 24 hrs</label>
        </div>
      </div>
      <div class="buttons-section">
        <button type="button" class="vsb-btn-link mr-10 color-green" @click="saveQuestion(question)">Save</button>
        <button type="button" class="vsb-btn-link mr-10 color-blue" @click="cancelQuestion(question)">Cancel</button>
        <!-- <button type="button" class="vsb-btn-link mr-10 color-red" @click="deleteQuestion(question)">Delete</button> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SurveyBuilder',
  data () {
    return {
      questionTypes: [
        { value: 'DEFAULT', label: '- Selecciona el tipo de pregunta -' },
        { value: 'BOOLEAN', label: 'Si o No' },
        { value: 'DATE', label: 'Fecha' },
        { value: 'MULTI_CHOICE', label: 'Selección múltiple' },
        { value: 'NUMBER', label: 'Número' },
        { value: 'SCALE', label: 'Escala' },
        { value: 'SINGLE_CHOICE', label: 'Seleccion Simple' },
        { value: 'TEXT', label: 'Texto' },
        { value: 'TIME', label: 'Tiempo' }
      ],
      question: this.options,
      selectedType: null
    }
  },
  props: ['options'],
  computed: {},
  watch: {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.question.type = this.question.type ? this.question.type : 'DEFAULT'
    this.selectedType = this.question.type

    window.console.log(this.question, this.selectedType)
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    /**
     * @desc {String} type
     * @param {String} type
     * @return {null}
     */
    questionTypeChanged (type) {
      this.question.type = this.selectedType
      switch (type) {
        case 'BOOLEAN':
          this.question.options = [{ body: 'Si', sequence: 1 }, { body: 'No', sequence: 2 }]
          break
        case 'SCALE':
          this.question.labels.length = 2
          break
        default:
          window.console.log('El tipo de pregunta no coincide')
      }
    },

    /**
     * @param {String} format
     * @return {null}
     */
    timeFormatModified (format) {
      window.console.log(format)
    },

    /**
     * @param {null}
     * @return {null}
     */
    addAnotherAnswer () {
      if (!this.question.options) {
        this.question.options = []
      }
      let maxSequence = Number(Math.max(...this.question.options.map(x => x.sequence)))
      if (!maxSequence) {
        maxSequence = this.question.options.length
      }
      this.question.options.push({ body: null, sequence: maxSequence + 1, nextQuestion: null, imageUrl: null }) // eslint-disable-line
      this.$forceUpdate()
    },

    /**
     * @param {Object, Number}  options, index
     * @return {null}
     */
    deleteQuestionOptionItem (options, index) {
      this.question.options.splice(index, 1)
    },

    /**
     * @param {null}
     * @return {String} guid
     */
    getGUID () {
      function s4 () {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1)
      }
      return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}`
    },

    /**
     * @param {Object} question
     * @return {null}
     */
    saveQuestion (question) {
      question.id = question.id ? question.id : this.getGUID() // eslint-disable-line
      this.$root.$emit('add-update-question', question)
      this.question = { type: 'DEFAULT' }
    },

    /**
     * @param {Object} question
     * @return {null}
     */
    cancelQuestion (question) {
      window.console.log(question)
      this.question = { type: 'DEFAULT' }
    },

    /**
     * @param {Object} question
     * @return {null}
     */
    deleteQuestion (question) {
      window.console.log(question)
    },

    /**
     * @param {Number} intervals
     * @return {null}
     */
    changeLabelsLength (intervals) {
      this.question.labels.length = intervals
    }
  }
}
</script>

<style scoped>
.p-0 {
   padding: 0px !important;
}
 .pt-0 {
   padding-top: 0px !important;
}
 .pr-0 {
   padding-right: 0px !important;
}
 .pb-0 {
   padding-bottom: 0px !important;
}
 .pl-0 {
   padding-left: 0px !important;
}
 .m-0 {
   margin: 0px !important;
}
 .mt-0 {
   margin-top: 0px !important;
}
 .mr-0 {
   margin-right: 0px !important;
}
 .mb-0 {
   margin-bottom: 0px !important;
}
 .ml-0 {
   margin-left: 0px !important;
}
 .fs-0 {
   font-size: 0px !important;
}
 .p-2 {
   padding: 2px !important;
}
 .pt-2 {
   padding-top: 2px !important;
}
 .pr-2 {
   padding-right: 2px !important;
}
 .pb-2 {
   padding-bottom: 2px !important;
}
 .pl-2 {
   padding-left: 2px !important;
}
 .m-2 {
   margin: 2px !important;
}
 .mt-2 {
   margin-top: 2px !important;
}
 .mr-2 {
   margin-right: 2px !important;
}
 .mb-2 {
   margin-bottom: 2px !important;
}
 .ml-2 {
   margin-left: 2px !important;
}
 .fs-2 {
   font-size: 2px !important;
}
 .p-4 {
   padding: 4px !important;
}
 .pt-4 {
   padding-top: 4px !important;
}
 .pr-4 {
   padding-right: 4px !important;
}
 .pb-4 {
   padding-bottom: 4px !important;
}
 .pl-4 {
   padding-left: 4px !important;
}
 .m-4 {
   margin: 4px !important;
}
 .mt-4 {
   margin-top: 4px !important;
}
 .mr-4 {
   margin-right: 4px !important;
}
 .mb-4 {
   margin-bottom: 4px !important;
}
 .ml-4 {
   margin-left: 4px !important;
}
 .fs-4 {
   font-size: 4px !important;
}
 .p-6 {
   padding: 6px !important;
}
 .pt-6 {
   padding-top: 6px !important;
}
 .pr-6 {
   padding-right: 6px !important;
}
 .pb-6 {
   padding-bottom: 6px !important;
}
 .pl-6 {
   padding-left: 6px !important;
}
 .m-6 {
   margin: 6px !important;
}
 .mt-6 {
   margin-top: 6px !important;
}
 .mr-6 {
   margin-right: 6px !important;
}
 .mb-6 {
   margin-bottom: 6px !important;
}
 .ml-6 {
   margin-left: 6px !important;
}
 .fs-6 {
   font-size: 6px !important;
}
 .p-8 {
   padding: 8px !important;
}
 .pt-8 {
   padding-top: 8px !important;
}
 .pr-8 {
   padding-right: 8px !important;
}
 .pb-8 {
   padding-bottom: 8px !important;
}
 .pl-8 {
   padding-left: 8px !important;
}
 .m-8 {
   margin: 8px !important;
}
 .mt-8 {
   margin-top: 8px !important;
}
 .mr-8 {
   margin-right: 8px !important;
}
 .mb-8 {
   margin-bottom: 8px !important;
}
 .ml-8 {
   margin-left: 8px !important;
}
 .fs-8 {
   font-size: 8px !important;
}
 .p-10 {
   padding: 10px !important;
}
 .pt-10 {
   padding-top: 10px !important;
}
 .pr-10 {
   padding-right: 10px !important;
}
 .pb-10 {
   padding-bottom: 10px !important;
}
 .pl-10 {
   padding-left: 10px !important;
}
 .m-10 {
   margin: 10px !important;
}
 .mt-10 {
   margin-top: 10px !important;
}
 .mr-10 {
   margin-right: 10px !important;
}
 .mb-10 {
   margin-bottom: 10px !important;
}
 .ml-10 {
   margin-left: 10px !important;
}
 .fs-10 {
   font-size: 10px !important;
}
 .color-blue {
   color: #4c8ce4;
}
 .color-orange {
   color: #ff9635;
}
 .color-red {
   color: #f06559;
}
 .color-green {
   color: #48bf7a;
}
 .vue-survey-builder-content {
   font-family: Arial, Helvetica, sans-serif;
   font-weight: normal;
   border: 1px solid #ddd;
   padding: 10px;
   box-shadow: 1px 1px 5px 0px #ddd;
   background-color: #f8f8f8;
   border-radius: 2px;
   margin: 12px 0;
}
 .vue-survey-builder-content input[type='text'], .vue-survey-builder-content input[type='number'], .vue-survey-builder-content select {
   width: 100%;
   padding: 0 10px;
   height: 38px;
   margin: 8px 0;
   display: inline-block;
   border: 1px solid #ccc;
   border-radius: 2px;
   box-sizing: border-box;
   font-size: 12px;
   background-color: #fff;
}
 .vue-survey-builder-content select {
   -webkit-appearance: none;
   -moz-appearance: none;
   background-position: right 50%;
   background-repeat: no-repeat;
   background-image: url(data:image/png;
  base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
}
 .vue-survey-builder-content button {
   padding: 10px;
   text-align: center;
   font-size: 14px;
   margin: 2px 0;
   cursor: pointer;
   border: 1px solid #f8f8f8;
}
 .vue-survey-builder-content .float-left {
   float: left;
}
 .vue-survey-builder-content .float-right {
   float: right;
}
 .vue-survey-builder-content .text-center {
   text-align: center;
}
 .vue-survey-builder-content .text-right {
   text-align: right;
}
 .vue-survey-builder-content .width-90 {
   width: 90% !important;
   display: inline-block;
}
 .vue-survey-builder-content .width-10 {
   width: 10% !important;
   display: inline-block;
}
 .vue-survey-builder-content .display-block {
   display: block;
   clear: both;
}
 .vue-survey-builder-content .display-inline-block {
   display: inline-block;
   clear: both;
}
 .vue-survey-builder-content .clear-both {
   clear: both;
}
 .vue-survey-builder-content .vsb-btn-link {
   border: none;
   background: none;
   padding: 5px;
}
 .vue-survey-builder-content .question-section {
   border: 1px solid #eaf0f4;
   padding: 10px;
   margin: auto;
}
 .vue-survey-builder-content .vsb-block {
   display: block;
   padding: 5px 0;
}
 .vue-survey-builder-content .vsb-choices-text {
   font-size: 16px;
}
 .vue-survey-builder-content .vsb-select {
   max-width: 200px;
}
 .vue-survey-builder-content .char-limit-input {
   max-width: 100px;
}
 .vue-survey-builder-content input:disabled, .vue-survey-builder-content textarea:disabled {
   background: #f8f8f8;
   pointer-events: none;
   cursor: no-drop;
}
 .vue-survey-builder-content .buttons-section {
   padding-top: 20px;
   clear: both;
}
 .vue-survey-builder-content *:focus {
   outline: none;
}
 .vue-survey-builder-content .scale-type .intervals input {
   width: 60px;
}
</style>
