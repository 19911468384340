import { casosService } from '../_services'

const state = {
  all: []
}

const actions = {
  cargarCasos ({ commit }) {
    commit('getAllRequest')

    casosService.getAll()
      .then(
        result => commit('getAllSuccess', result.data),
        error => commit('getAllFailure', error)
      )
  },

  creaCaso ({ dispatch, commit }, caso) {
    commit('registerRequest', caso)

    casosService.register(caso)
      .then(
        casox => {
          commit('registerSuccess', casox)
          setTimeout(() => {
            // display success message after route change completes
            dispatch('alerts/success', { message: 'Caso Guardado' }, { root: true })
          })
        },
        error => {
          commit('registerFailure', error)
          dispatch('alerts/error', { message: error }, { root: true })
        }
      )
  },

  dilitCaso ({ commit }, id) {
    commit('deleteRequest', id)

    casosService.delete(id)
      .then(
        caso => commit('deleteSuccess', id),
        error => commit('deleteFailure', { id, error: error.toString() })
      )
  },

  updeitCaso ({ commit }, caso) {
    commit('updateRequest', caso.id)
    casosService.update(caso)
      .then(
        caso => commit('updateSuccess', caso),
        error => commit('updateFailure', { caso, error: error.toString() })
      )
  }
}

const mutations = {
  getAllRequest (state) {
    state.all = { loading: true }
  },
  getAllSuccess (state, casos) {
    state.all = { items: casos }
  },
  getAllFailure (state, error) {
    state.all = { error }
  },
  registerRequest (state, caso) {
    state.status = { registering: true }
  },
  registerSuccess (state, caso) {
    state.all.items.push(caso)
  },
  registerFailure (state, error) {
    state.status = {}
    console.log(error)
  },
  deleteRequest (state, id) {
    // add 'deleting:true' property to caso being deleted
    state.all.items = state.all.items.map(caso =>
      caso.id === id
        ? { ...caso, deleting: true }
        : caso
    )
  },
  deleteSuccess (state, id) {
    // remove deleted caso from state
    state.all.items = state.all.items.filter(caso => caso.id !== id)
  },
  deleteFailure (state, { id, error }) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to caso
    state.all.items = state.all.items.map(caso => {
      if (caso.id === id) {
        // make copy of caso without 'deleting:true' property
        const { deleting, ...casoCopy } = caso
        // return copy of caso with 'deleteError:[error]' property
        return { ...casoCopy, deleteError: error }
      }

      return caso
    })
    console.log(error)
  },
  updateRequest (state, id) {
    // add 'updating:true' property to caso being updated
    state.all.items = state.all.items.map(caso =>
      caso.id === id
        ? { ...caso, updating: true }
        : caso
    )
  },
  updateSuccess (state, userx) {
    // update caso in state
    const item = state.all.items.find(caso => caso.id === userx.id)
    Object.assign(item, userx)
  },
  updateFailure (state, { id, error }) {
    // remove 'updating:true' property and add 'updateError:[error]' property to caso
    state.all.items = state.map(caso => {
      if (caso.id === id) {
        // make copy of caso without 'updating:true' property
        const { updating, ...casoCopy } = caso
        // return copy of caso with 'updateError:[error]' property
        return { ...casoCopy, updateError: error }
      }

      return caso
    })
  }
}
const getters = {
  activos (state) {
    return state.all.items.filter(el => {
      return el.estado === 'En Inspección' || el.estado === 'En Ejecución' || el.estado === 'Asignado' || el.estado === 'Esperando Aprobación'
    })
  },
  valpo (state) {
    return state.all.items.filter(el => {
      return el.sucursal === 'Valparaiso'
    })
  },
  esteYear (state) {
    var date = new Date().getFullYear()
    return state.all.items.filter(el => {
      return el.fechaAsignacion.substring(0, 4) === date.toString()
    })
  },
  lastYear (state) {
    return state.all.items.filter(el => {
      var date = new Date().getFullYear()
      date = date - 1
      if (el.fechaAsignacion !== undefined || el.fechaAsignacion !== null) {
        return el.fechaAsignacion.substring(0, 4) === date.toString()
      }
    })
  },
  stadistics (state) {
    return state.all.items
  }
}
export const casos = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
