<template>
  <v-container>
    <v-toolbar flat color="white">
      <v-toolbar-title>Casos 2021</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Busqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-toolbar>
    <v-data-table
      rows-per-page-text="Casos por pagina"
      v-model="selected"
      select-all
      ref="dataTable"
      :headers="headers"
      :items="this.casosFiltered"
      :search="search"
      class="elevation-1"
      disable-initial-sort
    >
      <template v-slot:items="props">
        <tr :style="{backgroundColor: (props.item.estado == 'En Ejecución' ? '#FFF176' : 'transparent' ) }">
        <td>
          <v-checkbox
            v-model="props.selected"
            primary
            hide-details
          ></v-checkbox>
        </td>
        <td>{{ props.item.numeroDeCaso }}</td>
        <td class="justify-center"><a :href="props.item.informeInicial"><v-icon>picture_as_pdf</v-icon></a></td>
        <td class="justify-center"><a :href="props.item.informeFinal"><v-icon>picture_as_pdf</v-icon></a></td>
        <td class="text-xs-right">{{ props.item.sucursal }}</td>
        <td class="text-xs-right">{{ props.item.origen }}</td>
        <td class="text-xs-right">{{ props.item.nombreAsegurado }}</td>
        <td class="text-xs-right">{{ props.item.email }}</td>
        <td class="text-xs-right">{{ props.item.telefono1 }}</td>
        <td class="text-xs-right">{{ props.item.direccion }}</td>
        <td class="text-xs-right">{{ props.item.comuna }}</td>
        <td class="text-xs-right">{{ props.item.estado }}</td>
        <td class="text-xs-right">{{ formatDate(props.item.fechaAsignacion) }}</td>
        <td class="text-xs-right">{{ props.item.observacion }}</td>
        </tr>
      </template>
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="warning">
          No hay resultados para: "{{ search }}".
        </v-alert>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import XLSX from 'xlsx'

export default {
  data: () => ({
    search: '',
    selected: [],
    sucursales: ['Valparaiso', 'Santiago', 'Otro'],
    origenes: ['Liberty', 'Banco Estado'],
    states: ['Asignado', 'En Inspección', 'Esperando Aprobación', 'En Ejecución', 'Rechazado', 'Sin Respuesta', 'Indemnizacion', 'Finalizado'],
    headers: [
      {
        text: 'Número de Caso',
        align: 'left',
        sortable: true,
        value: 'numeroDeCaso'
      },
      { text: 'Inf. Inicial', value: 'informeInicial', sortable: false },
      { text: 'Inf. Final', value: 'informeFinal', sortable: false },
      { text: 'Sucursal', value: 'sucursal' },
      { text: 'Origen', value: 'origen' },
      { text: 'Nombre Asegurado', value: 'nombreAsegurado' },
      { text: 'Email', value: 'email' },
      { text: 'Telefono 1', value: 'telefono1' },
      { text: 'Direccion', value: 'direccion' },
      { text: 'Comuna', value: 'comuna' },
      { text: 'Estado', value: 'estado' },
      { text: 'Fecha Asignación', value: 'fechaAsignacion' },
      { text: 'Observación', value: 'observacion' }
    ]
  }),
  computed: {
    ...mapGetters({
      casosFiltered: 'casos/esteYear'
    })
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      date = date.substring(0, 10)
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    exportXLSX () {
      if (this.selected.length > 1) {
        const data = XLSX.utils.json_to_sheet(this.selected,
          {
            header: ['numeroDeCaso', 'nombreAsegurado', 'nombreDeContacto', 'email', 'telefono1', 'telefono2', 'direccion', 'comuna', 'estado', 'sucursal', 'origen', 'observacion']
          })
        data.A1.v = 'Numero de Caso'
        data.B1.v = 'Nombre de Asegurado'
        data.C1.v = 'Nombre de Contacto'
        data.D1.v = 'E-mail'
        data.E1.v = 'Telefono1'
        data.F1.v = 'Telefono2'
        data.G1.v = 'Direccion'
        data.H1.v = 'Comuna'
        data.I1.v = 'Estado'
        data.J1.v = 'Sucursal'
        data.K1.v = 'Origen'
        data.L1.v = 'Observacion'
        const workbook = XLSX.utils.book_new()
        const filename = 'fmsoluciones'
        XLSX.utils.book_append_sheet(workbook, data, filename)
        XLSX.writeFile(workbook, `${filename}.xlsx`)
      } else if (this.$refs.dataTable.filteredItems.length !== this.$refs.dataTable.itemsLength) {
        var sure = confirm(`Esta seguro que desea exportar los ${this.$refs.dataTable.filteredItems.length} casos de esta pagina?`)
        if (sure === true) {
          const data = XLSX.utils.json_to_sheet(this.$refs.dataTable.filteredItems,
            {
              header: ['numeroDeCaso', 'nombreAsegurado', 'nombreDeContacto', 'email', 'telefono1', 'telefono2', 'direccion', 'comuna', 'estado', 'sucursal', 'origen', 'observacion']
            })
          data.A1.v = 'Numero de Caso'
          data.B1.v = 'Nombre de Asegurado'
          data.C1.v = 'Nombre de Contacto'
          data.D1.v = 'E-mail'
          data.E1.v = 'Telefono1'
          data.F1.v = 'Telefono2'
          data.G1.v = 'Direccion'
          data.H1.v = 'Comuna'
          data.I1.v = 'Estado'
          data.J1.v = 'Sucursal'
          data.K1.v = 'Origen'
          data.L1.v = 'Observacion'
          const workbook = XLSX.utils.book_new()
          const filename = 'fmsoluciones'
          XLSX.utils.book_append_sheet(workbook, data, filename)
          XLSX.writeFile(workbook, `${filename}.xlsx`)
          alert('Archivo Exportado')
        } else {
          alert('No se exporto nada')
        }
      } else if (this.$refs.dataTable.filteredItems.length === this.$refs.dataTable.itemsLength) {
        const data = XLSX.utils.json_to_sheet(this.$refs.dataTable.filteredItems,
          {
            header: ['numeroDeCaso', 'nombreAsegurado', 'nombreDeContacto', 'email', 'telefono1', 'telefono2', 'direccion', 'comuna', 'estado', 'sucursal', 'origen', 'observacion']
          })
        data.A1.v = 'Numero de Caso'
        data.B1.v = 'Nombre de Asegurado'
        data.C1.v = 'Nombre de Contacto'
        data.D1.v = 'E-mail'
        data.E1.v = 'Telefono1'
        data.F1.v = 'Telefono2'
        data.G1.v = 'Direccion'
        data.H1.v = 'Comuna'
        data.I1.v = 'Estado'
        data.J1.v = 'Sucursal'
        data.K1.v = 'Origen'
        data.L1.v = 'Observacion'
        const workbook = XLSX.utils.book_new()
        const filename = 'fmsoluciones'
        XLSX.utils.book_append_sheet(workbook, data, filename)
        XLSX.writeFile(workbook, `${filename}.xlsx`)
      } else {
        const data = XLSX.utils.json_to_sheet(this.casosFiltered,
          {
            header: ['numeroDeCaso', 'nombreAsegurado', 'nombreDeContacto', 'email', 'telefono1', 'telefono2', 'direccion', 'comuna', 'estado', 'sucursal', 'origen', 'observacion']
          })
        data.A1.v = 'Numero de Caso'
        data.B1.v = 'Nombre de Asegurado'
        data.C1.v = 'Nombre de Contacto'
        data.D1.v = 'E-mail'
        data.E1.v = 'Telefono1'
        data.F1.v = 'Telefono2'
        data.G1.v = 'Direccion'
        data.H1.v = 'Comuna'
        data.I1.v = 'Estado'
        data.J1.v = 'Sucursal'
        data.K1.v = 'Origen'
        data.L1.v = 'Observacion'
        const workbook = XLSX.utils.book_new()
        const filename = 'fmsoluciones'
        XLSX.utils.book_append_sheet(workbook, data, filename)
        XLSX.writeFile(workbook, `${filename}.xlsx`)
      }
    }
  }
}
</script>
