<template>
<v-app class="">
<Nab />
<v-container class="mt-2">
 <v-flex xs12 sm12>
      <div class="text-xs-left titulo1">
        <h2><b>Nosotros</b></h2>
        <hr class="linea2">
      </div>
  </v-flex>
</v-container>
<v-container class="">
  <v-layout row wrap align-center class="text-xs-left">
    <v-flex xs12 sm12 md6 lg6 xl6 class="">
      <p class="grey--text parrafo">Desarrollamos proyectos de infraestructura, reparaciones y soluciones buscando cumplir con las necesidades de nuestros clientes, ofertando diseños a la vanguardia, cumpliendo con los estándares de calidad. Innovamos con las últimas tendencias encontradas en el sector de la construcción, buscando mejorar los procesos para hacer más eficiente el producto final.</p>
      <p class="grey--text parrafo">Buscamos la satisfacción de nuestros clientes, desde el inicio de cada proyecto, hasta la entrega de los mismos, retándonos continuamente para alcanzar los máximos niveles de calidad en cada trabajo realizado.</p>
    </v-flex>

    <v-flex xs12 sm12 md6 lg6 xl6 class="mt-2">
      <v-img class="mx-3" :src="require('../assets/imagen-5.jpg')" contain max-height="335"></v-img>
    </v-flex>
  </v-layout>
</v-container>
<v-content class="bg-red">
  <v-container>
    <v-layout row wrap align-center class="text-xs-left">
      <v-flex xs12 sm12 md6 lg6 xl6 class="">
        <v-img class="" :src="require('../assets/imagen.4.jpg')" max-height="350"></v-img>
      </v-flex>
      <v-flex xs12 sm12 md6 lg6 xl6 class="parrafo1">
        <h2><b>Nuestra Visión</b></h2>
        <p class="">Somos una empresa líder en el sector de la construcción con un equipo comprometido, evolucionando constantemente nuestros servicios para ofrecer productos innovadores que cumplan las necesidades de los clientes, cumplimiento, diseño y conciencia de servicio al cliente que garanticen solidez de la empresa.</p>
        <h2><b>Nuestra Misión</b></h2>
        <p class="">Desarrollamos proyectos de infraestructura, reparaciones y soluciones buscando satisfacer las necesidades de nuestros clientes, ofertando diseños a la vanguardia, cumpliendo con los estándares de calidad.Innovar con las últimas tendencias encontradas en el sector de la construcción, buscando mejorar los procesos para hacer más eficiente el producto final.</p>
      </v-flex>
    </v-layout>
  </v-container>
  </v-content>
  <v-container class="">
   <v-flex xs12 sm12 class="my-2">
        <div class="text-xs-left titulo1">
          <h2><b>Nuestro Equipo</b></h2>
          <hr class="linea2">
        </div>
      </v-flex>
  </v-container>
  <v-container grid-list-xl class="my-2">
  <v-layout
    justify-center
    wrap
  >
    <v-flex
      v-for="(member, i) in team"
      :key="i"
      xs12
      sm6
      md3
    >
      <Member :value="member" />
    </v-flex>
  </v-layout>
</v-container>
<Futer />
</v-app>
</template>

<script>
import Nab from '@/views/components/Nav.vue'
import Member from '@/views/components/Member.vue'
import Futer from '@/views/components/Footer.vue'
export default {
  name: 'nosotros',
  components: {
    Nab,
    Member,
    Futer
  },
  data: () => ({
    team: [
      {
        title: 'Gerente General',
        name: 'Franco Miranda',
        email: 'fmiranda@fmsoluciones.cl',
        avatar: 'accessoriesType=Blank&avatarStyle=Transparent&clotheColor=Heather&clotheType=Hoodie&eyeType=Default&eyebrowType=Default&facialHairType=Blank&graphicType=Bear&hairColor=BrownDark&mouthType=Smile&skinColor=Pale&topType=ShortHairShortFlat'
      },
      {
        title: 'Jefe de Operaciones',
        name: 'Diego Perez',
        email: 'dperez@fmsoluciones.cl',
        avatar: 'accessoriesType=Blank&avatarStyle=Transparent&clotheColor=Heather&clotheType=Hoodie&eyeType=Default&eyebrowType=Default&facialHairType=Blank&graphicType=Bear&hairColor=BrownDark&mouthType=Smile&skinColor=Pale&topType=ShortHairShortFlat'
      },
      {
        title: 'Asistente de Operaciones',
        name: 'Andrea Perez',
        email: 'aperez@fmsoluciones.cl',
        avatar: 'avatarStyle=Transparent&topType=LongHairStraight&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Surprised&eyebrowType=DefaultNatural&mouthType=Smile&skinColor=Light'
      },
      {
        title: 'Asistente Comercial',
        name: 'Tania Sotomayor',
        email: 'tsotomayor@fmsoluciones.cl',
        avatar: 'avatarStyle=Transparent&topType=LongHairStraight&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Surprised&eyebrowType=DefaultNatural&mouthType=Smile&skinColor=Light'
      }
    ]
  })
}
</script>

<style>
.parrafo{
  letter-spacing: 2px;
  padding: 0 30px;
  font-size: 12px;
  text-align: justify;
}
.parrafo1 {
  padding: 0 30px;
  color: white;
  text-align: justify;
}
.parrafo1 h2{
  letter-spacing: 2px;
  margin-bottom: 7px;
}
.titulo1 h2{
  letter-spacing: 2px;
  color: grey;
  font-weight: 500;
  font-size: 25px;
}
.linea2{
  border: 1px solid #cb0e11;
}
.bg-red{
  background-color: #cb0e11;
  background-size: 100%;
}
</style>
