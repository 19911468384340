<template>
  <v-container>
    <v-toolbar flat color="white">
      <v-toolbar-title>Encuesta</v-toolbar-title>
    </v-toolbar>
    <br>
    <v-layout class="test-survey-builder">
      <v-flex>
        <QuestionsView :questions="questionsList" :readOnly="true" />
        <div v-if="addQuestion">
          <SurveyBuilder :options="sampleQuestion" />
        </div>
        <div class="pt-10">
          <v-btn dark type="button" color="fmcolor" class="" v-on:click="addNewQuestion()">Agregar Pregunta</v-btn>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { SurveyBuilder, QuestionsView, SurveyBuilderJson } from './questions'

export default {
  name: 'encuesta',
  data () {
    return {
      questionsList: [],
      addQuestion: false
    }
  },
  mounted () {
    this.$root.$on('add-update-question', q => {
      this.updateQuestionsList(q)
    })
  },
  components: { SurveyBuilder, QuestionsView },
  methods: {
    updateQuestionsList (question) {
      const questionIndex = this.questionsList.findIndex(x => x.id === question.id)
      if (questionIndex >= 0) {
        this.questionsList.splice(questionIndex, 1, question)
      } else {
        this.questionsList.push(JSON.parse(JSON.stringify(question)))
      }
      this.addQuestion = false
      this.$root.$emit('selected-question', null)
      window.console.log(question, this.addQuestion, this.questionsList)
    },
    addNewQuestion () {
      this.sampleQuestion = JSON.parse(JSON.stringify(SurveyBuilderJson))
      this.addQuestion = true
    }
  }
}
</script>

<style>
.add_another_btn {
  font-size: 14px;
  background-color: transparent;
  border-color: #4c8ce4;
  color: #4c8ce4;
  padding: 8px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
</style>
