<template>
  <v-container grid-list-md text-xs-center>
    <v-layout row wrap>
      <v-flex xs12 v-if="loaded1 === true">
        <h1>Estadísticas Valparaiso</h1>
        <MyChart :chart-data="chart1.data" :options="chart1.options" v-if="loaded1"/>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import MyChart from '@/views/components/MyChart'
export default {
  name: 'estadisticas',
  data: () => ({
    casos: undefined,
    loaded: false,
    loaded1: false,
    contadores: false,
    contadorValpo1: 0,
    contadorValpo2: 0,
    contadorValpo3: 0,
    contadorValpo4: 0,
    contadorValpo5: 0,
    contadorValpo6: 0,
    contadorValpo7: 0,
    contadorValpo8: 0,
    porcentajesV: [0, 0, 0, 0, 0, 0, 0, 0],
    totalValpo: 0,
    chart1: {
      data: {
        datasets: [{
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: [
            'blue',
            'orange',
            'cyan',
            'yellow',
            'red',
            'gray',
            'green',
            'purple'
          ]
        }],
        labels: [
          'Asignado',
          'Esperando Respuesta',
          'En Inspección',
          'En Ejecución',
          'Rechazado',
          'Sin Respuesta',
          'Finalizado',
          'Indemnización'
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'left',
          align: 'center'
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return data.labels.tooltipItem.index + ': ' + data.datasets[0].datatooltipItemindex + '%'
            }
          }
        }
      }
    }
  }),
  methods: {
    get () {
      this.axios.get('/casos').then(
        result => {
          if (result.data.length > 0) this.casos = result.data
          this.counter()
          this.percent()
          this.fillChart()
          this.loaded = true
          this.loaded1 = true
        },
        error => console.log(error)
      )
    },
    counter () {
      this.casos.forEach(caso => {
        if (caso.sucursal === 'Valparaiso') this.totalValpo++
        if (caso.estado === 'Asignado' && caso.sucursal === 'Valparaiso') this.contadorValpo1++
        if (caso.estado === 'Esperando Respuesta' && caso.sucursal === 'Valparaiso') this.contadorValpo2++
        if (caso.estado === 'En Inspección' && caso.sucursal === 'Valparaiso') this.contadorValpo3++
        if (caso.estado === 'En Ejecución' && caso.sucursal === 'Valparaiso') this.contadorValpo4++
        if (caso.estado === 'Rechazado' && caso.sucursal === 'Valparaiso') this.contadorValpo5++
        if (caso.estado === 'Sin Respuesta' && caso.sucursal === 'Valparaiso') this.contadorValpo6++
        if (caso.estado === 'Finalizado' && caso.sucursal === 'Valparaiso') this.contadorValpo7++
        if (caso.estado === 'Indemnización' && caso.sucursal === 'Valparaiso') this.contadorValpo8++
      })
    },
    percent () {
      this.porcentajesV[0] = ((this.contadorValpo1 / this.totalValpo) * 100).toFixed(1)
      this.porcentajesV[1] = ((this.contadorValpo2 / this.totalValpo) * 100).toFixed(1)
      this.porcentajesV[2] = ((this.contadorValpo3 / this.totalValpo) * 100).toFixed(1)
      this.porcentajesV[3] = ((this.contadorValpo4 / this.totalValpo) * 100).toFixed(1)
      this.porcentajesV[4] = ((this.contadorValpo5 / this.totalValpo) * 100).toFixed(1)
      this.porcentajesV[5] = ((this.contadorValpo6 / this.totalValpo) * 100).toFixed(1)
      this.porcentajesV[6] = ((this.contadorValpo7 / this.totalValpo) * 100).toFixed(1)
      this.porcentajesV[7] = ((this.contadorValpo8 / this.totalValpo) * 100).toFixed(1)
    },
    fillChart () {
      this.chart1.data.datasets[0].data[0] = this.porcentajesV[0]
      this.chart1.data.datasets[0].data[1] = this.porcentajesV[1]
      this.chart1.data.datasets[0].data[2] = this.porcentajesV[2]
      this.chart1.data.datasets[0].data[3] = this.porcentajesV[3]
      this.chart1.data.datasets[0].data[4] = this.porcentajesV[4]
      this.chart1.data.datasets[0].data[5] = this.porcentajesV[5]
      this.chart1.data.datasets[0].data[6] = this.porcentajesV[6]
      this.chart1.data.datasets[0].data[7] = this.porcentajesV[7]
      this.chart1.data.datasets[0].data[8] = this.porcentajesV[8]
    }
  },
  components: {
    MyChart
  },
  created () {
    this.get()
  }
}
</script>
