import { userService } from '../_services'

const state = {
  all: []
}

const actions = {
  getAll ({ commit }) {
    commit('getAllRequest')

    userService.getAll()
      .then(
        users => commit('getAllSuccess', users),
        error => commit('getAllFailure', error)
      )
  },

  register ({ dispatch, commit }, payload) {
    commit('registerRequest', payload)

    userService.register(payload)
      .then(
        userx => {
          commit('registerSuccess', userx)
          /* setTimeout(() => {
            // display success message after route change completes
            dispatch('alerts/success', 'Registration successful', { root: true })
          }) */
        },
        error => {
          commit('registerFailure', error)
          dispatch('alerts/error', { message: error }, { root: true })
        }
      )
  },

  delete ({ commit }, id) {
    commit('deleteRequest', id)

    userService.delit(id)
      .then(
        user => commit('deleteSuccess', id),
        error => commit('deleteFailure', { id, error: error.toString() })
      )
  },

  update ({ commit }, user) {
    commit('updateRequest', user.id)
    userService.update(user)
      .then(
        user => commit('updateSuccess', user),
        error => commit('updateFailure', { user, error: error.toString() })
      )
  }
}

const mutations = {
  getAllRequest (state) {
    state.all = { loading: true }
  },
  getAllSuccess (state, users) {
    state.all = { items: users }
  },
  getAllFailure (state, error) {
    state.all = { error }
  },
  registerRequest (state, user) {
    state.status = { registering: true }
  },
  registerSuccess (state, user) {
    state.all.items.push(user)
  },
  registerFailure (state, error) {
    state.status = {}
  },
  deleteRequest (state, id) {
    // add 'deleting:true' property to user being deleted
    state.all.items = state.all.items.map(user =>
      user.id === id
        ? { ...user, deleting: true }
        : user
    )
  },
  deleteSuccess (state, id) {
    // remove deleted user from state
    state.all.items = state.all.items.filter(user => user.id !== id)
  },
  deleteFailure (state, { id, error }) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to user
    state.all.items = state.items.map(user => {
      if (user.id === id) {
        // make copy of user without 'deleting:true' property
        const { deleting, ...userCopy } = user
        // return copy of user with 'deleteError:[error]' property
        return { ...userCopy, deleteError: error }
      }

      return user
    })
  },
  updateRequest (state, id) {
    // add 'updating:true' property to user being updated
    state.all.items = state.all.items.map(user =>
      user.id === id
        ? { ...user, updating: true }
        : user
    )
  },
  updateSuccess (state, userx) {
    // update user in state
    const item = state.all.items.find(user => user.id === userx.id)
    Object.assign(item, userx)
  },
  updateFailure (state, { id, error }) {
    // remove 'updating:true' property and add 'updateError:[error]' property to user
    state.all.items = state.items.map(user => {
      if (user.id === id) {
        // make copy of user without 'updating:true' property
        const { updating, ...userCopy } = user
        // return copy of user with 'updateError:[error]' property
        return { ...userCopy, updateError: error }
      }

      return user
    })
  }
}

export const users = {
  namespaced: true,
  state,
  actions,
  mutations
}
