<template>
  <v-container grid-list-md text-xs-center>
    <v-layout row wrap>
      <!--v-flex class="">
        <v-btn class="blue lighten-2 black--text" v-on:click="loadStgo">Santiago</v-btn>
        <v-btn class="blue lighten-2 black--text" v-on:click="loadValpo">Valparaiso</v-btn>
        <v-btn class="blue lighten-2 black--text" v-on:click="limpiar">Limpiar</v-btn>
      </v-flex-->
      <v-flex xs12>
        <h1>Estadísticas Santiago</h1>
        <MyChart :chart-data="chart.data" :options="chart.options" v-if="loaded === true"/>
      </v-flex>
      <!--v-flex xs12 v-if="loadedV === true">
        <h1>Estadísticas Valparaiso</h1>
        <MyChart :chart-data="chart1.data" :options="chart1.options" v-if="loaded1 && loadedV"/>
      </v-flex-->
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from 'vuex'

import MyChart from '@/views/components/MyChart'
export default {
  name: 'estadisticas',
  data: () => ({
    loaded: false,
    loaded1: false,
    loadedS: false,
    loadedV: false,
    contadores: false,
    contadorStgo1: 0,
    contadorStgo2: 0,
    contadorStgo3: 0,
    contadorStgo4: 0,
    contadorStgo5: 0,
    contadorStgo6: 0,
    contadorStgo7: 0,
    contadorStgo8: 0,
    contadorValpo1: 0,
    contadorValpo2: 0,
    contadorValpo3: 0,
    contadorValpo4: 0,
    contadorValpo5: 0,
    contadorValpo6: 0,
    contadorValpo7: 0,
    contadorValpo8: 0,
    porcentajes: [0, 0, 0, 0, 0, 0, 0, 0],
    porcentajesV: [0, 0, 0, 0, 0, 0, 0, 0],
    totalStgo: 0,
    totalValpo: 0,
    chart: {
      data: {
        datasets: [{
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: [
            'blue',
            'orange',
            'cyan',
            'yellow',
            'red',
            'gray',
            'green',
            'purple'
          ]
        }],
        labels: [
          'Asignado',
          'Esperando Respuesta',
          'En Inspección',
          'En Ejecución',
          'Rechazado',
          'Sin Respuesta',
          'Finalizado',
          'Indemnización'
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'left',
          align: 'center'
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return data.labels.tooltipItem.index + ': ' + data.datasets[0].datatooltipItemindex + '%'
            }
          }
        }
      }
    },
    chart1: {
      data: {
        datasets: [{
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: [
            'blue',
            'orange',
            'cyan',
            'yellow',
            'red',
            'gray',
            'green',
            'purple'
          ]
        }],
        labels: [
          'Asignado',
          'Esperando Respuesta',
          'En Inspección',
          'En Ejecución',
          'Rechazado',
          'Sin Respuesta',
          'Finalizado',
          'Indemnización'
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'left',
          align: 'center'
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return data.labels.tooltipItem.index + ': ' + data.datasets[0].datatooltipItemindex + '%'
            }
          }
        }
      }
    }
  }),
  computed: {
    ...mapState({
      casos: state => state.casos.all
    })
  },
  methods: {
    counter () {
      this.casos.items.forEach(caso => {
        if (caso.sucursal === 'Santiago') this.totalStgo++
        if (caso.estado === 'Asignado' && caso.sucursal === 'Santiago') this.contadorStgo1++
        if (caso.estado === 'Esperando Respuesta' && caso.sucursal === 'Santiago') this.contadorStgo2++
        if (caso.estado === 'En Inspección' && caso.sucursal === 'Santiago') this.contadorStgo3++
        if (caso.estado === 'En Ejecución' && caso.sucursal === 'Santiago') this.contadorStgo4++
        if (caso.estado === 'Rechazado' && caso.sucursal === 'Santiago') this.contadorStgo5++
        if (caso.estado === 'Sin Respuesta' && caso.sucursal === 'Santiago') this.contadorStgo6++
        if (caso.estado === 'Finalizado' && caso.sucursal === 'Santiago') this.contadorStgo7++
        if (caso.estado === 'Indemnización' && caso.sucursal === 'Santiago') this.contadorStgo8++
      })
      this.casos.items.forEach(caso => {
        if (caso.sucursal === 'Valparaiso') this.totalValpo++
        if (caso.estado === 'Asignado' && caso.sucursal === 'Valparaiso') this.contadorValpo1++
        if (caso.estado === 'Esperando Respuesta' && caso.sucursal === 'Valparaiso') this.contadorValpo2++
        if (caso.estado === 'En Inspección' && caso.sucursal === 'Valparaiso') this.contadorValpo3++
        if (caso.estado === 'En Ejecución' && caso.sucursal === 'Valparaiso') this.contadorValpo4++
        if (caso.estado === 'Rechazado' && caso.sucursal === 'Valparaiso') this.contadorValpo5++
        if (caso.estado === 'Sin Respuesta' && caso.sucursal === 'Valparaiso') this.contadorValpo6++
        if (caso.estado === 'Finalizado' && caso.sucursal === 'Valparaiso') this.contadorValpo7++
        if (caso.estado === 'Indemnización' && caso.sucursal === 'Valparaiso') this.contadorValpo8++
      })
    },
    percent () {
      this.porcentajes[0] = ((this.contadorStgo1 / this.totalStgo) * 100).toFixed(1)
      this.porcentajes[1] = ((this.contadorStgo2 / this.totalStgo) * 100).toFixed(1)
      this.porcentajes[2] = ((this.contadorStgo3 / this.totalStgo) * 100).toFixed(1)
      this.porcentajes[3] = ((this.contadorStgo4 / this.totalStgo) * 100).toFixed(1)
      this.porcentajes[4] = ((this.contadorStgo5 / this.totalStgo) * 100).toFixed(1)
      this.porcentajes[5] = ((this.contadorStgo6 / this.totalStgo) * 100).toFixed(1)
      this.porcentajes[6] = ((this.contadorStgo7 / this.totalStgo) * 100).toFixed(1)
      this.porcentajes[7] = ((this.contadorStgo8 / this.totalStgo) * 100).toFixed(1)

      this.porcentajesV[0] = ((this.contadorValpo1 / this.totalValpo) * 100).toFixed(1)
      this.porcentajesV[1] = ((this.contadorValpo2 / this.totalValpo) * 100).toFixed(1)
      this.porcentajesV[2] = ((this.contadorValpo3 / this.totalValpo) * 100).toFixed(1)
      this.porcentajesV[3] = ((this.contadorValpo4 / this.totalValpo) * 100).toFixed(1)
      this.porcentajesV[4] = ((this.contadorValpo5 / this.totalValpo) * 100).toFixed(1)
      this.porcentajesV[5] = ((this.contadorValpo6 / this.totalValpo) * 100).toFixed(1)
      this.porcentajesV[6] = ((this.contadorValpo7 / this.totalValpo) * 100).toFixed(1)
      this.porcentajesV[7] = ((this.contadorValpo8 / this.totalValpo) * 100).toFixed(1)
    },
    fillChart () {
      this.chart.data.datasets[0].data[0] = this.porcentajes[0]
      this.chart.data.datasets[0].data[1] = this.porcentajes[1]
      this.chart.data.datasets[0].data[2] = this.porcentajes[2]
      this.chart.data.datasets[0].data[3] = this.porcentajes[3]
      this.chart.data.datasets[0].data[4] = this.porcentajes[4]
      this.chart.data.datasets[0].data[5] = this.porcentajes[5]
      this.chart.data.datasets[0].data[6] = this.porcentajes[6]
      this.chart.data.datasets[0].data[7] = this.porcentajes[7]
      this.chart.data.datasets[0].data[8] = this.porcentajes[8]
      // 2nd chart
      this.chart1.data.datasets[0].data[0] = this.porcentajesV[0]
      this.chart1.data.datasets[0].data[1] = this.porcentajesV[1]
      this.chart1.data.datasets[0].data[2] = this.porcentajesV[2]
      this.chart1.data.datasets[0].data[3] = this.porcentajesV[3]
      this.chart1.data.datasets[0].data[4] = this.porcentajesV[4]
      this.chart1.data.datasets[0].data[5] = this.porcentajesV[5]
      this.chart1.data.datasets[0].data[6] = this.porcentajesV[6]
      this.chart1.data.datasets[0].data[7] = this.porcentajesV[7]
      this.chart1.data.datasets[0].data[8] = this.porcentajesV[8]
    },
    loadValpo () {
      if (this.loadedS === true && this.loaded === true) {
        alert('Primero presiona el boton Limpiar antes de crear un nuevo grafico')
      } else if (this.loadedV === false) {
        this.loadedV = true
        this.loaded1 = true
        this.loaded = false
      } else {
        this.loadedV = false
        this.loaded = false
      }
    },
    loadStgo () {
      if (this.loadedV === true && this.loaded1 === true) {
        alert('Primero presiona el boton Limpiar antes de crear un nuevo grafico')
      } else if (this.loadedS === false) {
        this.loadedS = true
        this.loaded = true
        this.loaded1 = false
      } else {
        this.loadedS = false
        this.loaded = false
      }
    },
    limpiar () {
      this.loadedS = false
      this.loaded = false

      this.loadedV = false
      this.loaded1 = false
    }
  },
  components: {
    MyChart
  },
  created () {
    this.counter()
    this.percent()
    this.fillChart()
    this.loaded = true
    this.loaded1 = true
  }
}
</script>
