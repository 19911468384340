<template>
<v-app>
<Nab />
<v-content class="">
<section>
  <v-layout
    column
    wrap
    class="my-3"
    align-center
  >
  <v-flex xs12 sm4 class="mx-5">
    <v-container class="text-xs-left">
      <h2 class="my-2">Nuestros Servicios</h2>
      <hr class="linea2">
      <p class="my-4">Buscamos la satisfacción de nuestros clientes, desde el inicio de cada proyecto, hasta la entrega de los mismos, retándonos continuamente para alcanzar los máximos niveles de calidad en cada trabajo realizado. Mejorando continuamente hacia la excelencia como forma de trabajar, buscando innovación, manteniendo el compromiso con una sólida ética laboral, integridad y honestidad, así como con el cumplimiento de las normas de control de calidad y diseños existentes.</p>
    </v-container>
  </v-flex>
    <v-flex xs12 >
      <v-container grid-list-xl>
        <v-layout row wrap align-center>
          <v-flex xs12 md4>
            <v-card class="elevation-0 transparent">
              <v-card-text class="text-xs-center">
              <v-img class="mt-5" :src="require('../assets/icons/009-hammer.svg')" alt="Construcción" contain max-height=80></v-img>
              </v-card-text>
              <v-card-title primary-title class="layout justify-center">
                <div class="headline">Construccion</div>
              </v-card-title>
              <v-card-text>
              Realizamos todo tipo de construcciones desde los cimientos, Albañilería, Metalcom, Paneles Sip, Tabiquería
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 md4>
            <v-card class="elevation-0 transparent">
              <v-card-text class="text-xs-center">
                <v-img class="mt-5" :src="require('../assets/icons/002-paint-roller.svg')" alt="Remodelaciones" contain max-height=80></v-img>
              </v-card-text>
              <v-card-title primary-title class="layout justify-center">
                <div class="headline text-xs-center">Remodelaciones</div>
              </v-card-title>
              <v-card-text>
                Remodelamos tu propiedad, Proyectos de Interiorismos según planos. Ampliaciones
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 md4>
            <v-card class="elevation-0 transparent">
              <v-card-text class="text-xs-center">
                <v-img class="mt-5" :src="require('../assets/icons/welding.svg')" alt="Estructuras Metalicas" contain max-height=80></v-img>
              </v-card-text>
              <v-card-title primary-title class="layout justify-center">
                <div class="headline text-xs-center">Estructuras Metalicas</div>
              </v-card-title>
              <v-card-text>
                Estructuras metalicas desde: Protecciones, Rejas, Portones de Corredera y Batientes, Galpones.
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 md4>
            <v-card class="elevation-0 transparent">
              <v-card-text class="text-xs-center">
                <v-img class="mt-5" :src="require('../assets/icons/intercom.svg')" alt="" contain max-height=80></v-img>
              </v-card-text>
              <v-card-title primary-title class="layout justify-center">
                <div class="headline text-xs-center">Automatización y Citofonía</div>
              </v-card-title>
              <v-card-text>
                Realizamos la Instalacion y Automatización de Portones corredera y batientes. Citofonía
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 md4>
            <v-card class="elevation-0 transparent">
              <v-card-text class="text-xs-center">
                <v-img class="mt-5" :src="require('../assets/icons/socket.svg')" alt="Electricidad" contain max-height=80></v-img>
              </v-card-text>
              <v-card-title primary-title class="layout justify-center">
                <div class="headline text-xs-center">Electricidad</div>
              </v-card-title>
              <v-card-text>
                Instalaciones eléctricas completas. Personal Autorizado (SEC)
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 md4>
            <v-card class="elevation-0 transparent">
              <v-card-text class="text-xs-center">
                <v-img class="mt-5" :src="require('../assets/icons/pipe.svg')" alt="Electricidad" contain max-height=80></v-img>
              </v-card-text>
              <v-card-title primary-title class="layout justify-center">
                <div class="headline text-xs-center">Gasfiteria</div>
              </v-card-title>
              <v-card-text>
                Instalaciones Sanitarias Reparaciones, Filtraciones, Rotura de cañerías.
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</section>
<Futer />
</v-content>
</v-app>
</template>

<script>
import Nab from '@/views/components/Nav.vue'
import Futer from '@/views/components/Footer.vue'
export default {
  name: 'servicios',
  components: {
    Nab,
    Futer
  },
  data: () => ({

  })
}
</script>
<style scoped>
.bg-blurrr{
  background-image: url("../assets/bg2.jpg");
  background-size: cover;
  filter: blur(3px);
  -webkit-filter: blur(3px);
}
.bg-text{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
/*  width: 90%;*/
  padding: 10px;
  text-align: center;
}
</style>
