<template>
<v-app>
<Nab />
<v-content class="">
<section>
  <div class="black--text my-5">
    <v-layout column align-center justify-center class="black--text">
      <h1 class="black--text mb-2 display-1 text-xs-center">CONFIANZA EN NUESTROS PROYECTOS</h1>
      <div class="subheading mb-3 text-xs-center">CALIDAD EN NUESTROS TRABAJOS</div>
    </v-layout>
  </div>
</section>
</v-content>
<v-content class="">
  <section>
    <v-container grid-list-xl>
      <v-layout wrap>
        <v-flex
        xs12
        sm6
        lg4

        v-for="proyecto in proyectos"
        :key="proyecto.img1"
        >
        <v-card hover href="#">
        <VueCompareImage :leftImage="require(`@/assets/proyectos/${proyecto.img1}`)" :rightImage="require(`@/assets/proyectos/${proyecto.img2}`)" />
        </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</v-content>
<Futer />
</v-app>
</template>

<script>
import VueCompareImage from 'vue-compare-image'
import Nab from '@/views/components/Nav.vue'
import Futer from '@/views/components/Footer.vue'

export default {
  name: 'proyectos',
  components: {
    Nab,
    Futer,
    VueCompareImage
  },
  data: () => ({
    proyectos: [
      {
        img1: 'pasto1.jpg',
        img2: 'pasto2.jpg'
      },
      {
        img1: 'frontis1.jpg',
        img2: 'frontis2.jpg'
      },
      {
        img1: 'ventana1.jpg',
        img2: 'ventana2.jpg'
      },
      {
        img1: 'porcelanato1.jpg',
        img2: 'porcelanato2.jpg'
      },
      {
        img1: 'terraza1.jpg',
        img2: 'terraza2.jpg'
      },
      {
        img1: 'cielo.jpg',
        img2: 'cielo2.jpg'
      }
    ],
    props: {
      leftImage: {
        type: String,
        default: ''
      },
      leftImageAlt: {
        type: String,
        default: null
      },
      leftLabel: {
        type: String,
        default: ''
      },
      rightImage: {
        type: String,
        default: ''
      },
      rightImageAlt: {
        type: String,
        default: null
      },
      rightLabel: {
        type: String,
        default: ''
      },
      hover: {
        type: Boolean,
        default: false
      },
      handleSize: {
        type: Number,
        default: 40
      },
      sliderLineWidth: {
        type: Number,
        default: 2
      },
      sliderPositionPercentage: {
        type: Number,
        default: 0.5
      }
    }
  })
}
</script>
<style scoped>
.bg-blurrr{
  background-image: url("../assets/bg2.jpg");
  background-size: cover;
  filter: blur(3px);
  -webkit-filter: blur(3px);
}
.bg-text{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
/*  width: 90%;*/
  padding: 10px;
  text-align: center;
}
</style>
