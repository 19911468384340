<template>
<v-content>
  <section class="fmcolor futer">
    <v-container grid-list-xl>
      <v-layout row wrap justify-center class="my-5">
        <v-flex xs12 sm6>
          <v-img :src="require('@/assets/logofuter.png')" max-height="200" contain></v-img>
        </v-flex>
        <v-flex xs12 sm6>
          <v-card class="elevation-0 transparent">
            <v-card-title primary-title class="layout justify-left">
              <div class="headline lightgrey--text text--lighten-1">Contáctenos</div>
            </v-card-title>
            <v-list class="transparent lightgrey--text text--lighten-1">
              <v-list-tile>
                <v-list-tile-action>
                  <v-icon class="lightgrey--text text--lighten-1">phone</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title class="lightgrey--text text--lighten-1"><a class="foterra" href="tel:+56232689658"> (+56) 2 3268 9658</a></v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-action>
                  <v-icon class="lightgrey--text text--lighten-1">place</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title class="lightgrey--text text--lighten-1">Portugal #412, of. 502, Santiago, Chile</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-action>
                  <v-icon class="lightgrey--text text--lighten-1">email</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title class="lightgrey--text text--lighten-1"><a class="foterra" href="mailto:contacto@fmsoluciones.cl">contacto@fmsoluciones.cl</a></v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
  <v-footer class="lightgrey darken-4">
        <v-layout row wrap align-center>
          <v-flex xs12 class="text-xs-center">
            <div class="white--text ml-3">
              Hecho con
              <v-icon class="red--text">favorite</v-icon>
              por el Equipo de <a class="white--text" href="https://www.ecode.cl" target="_blank">eCode.cl</a>
            </div>
          </v-flex>
        </v-layout>
      </v-footer>
</v-content>
</template>

<script>
export default {
  name: 'futer'
}
</script>

<style scope>
.futer a{
  color: white;
  text-decoration: none;
}
a:hover{
  color: #1976d2;
}
</style>
