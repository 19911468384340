<template>
<v-app light>
<Nab />
<v-content class="ma-0 pa-0">
  <v-parallax class="bg-blurrr"></v-parallax>
  <v-container align-center>
    <v-layout class="bg-text">
      <v-flex xs12 sm12 md12 lg12 xl12>
        <v-card class="search" style="background-color: rgba(185, 5, 4,0.9);" max-width="324" height="300">
          <form action="POST" @submit.prevent="query">
            <v-card-title class="headline py-4 text-xs-center">Ingresa tu Número de Siniestro</v-card-title>
            <v-card-text>
              <v-text-field outline class="ma-2 white" color="fmcolor" v-model="numeroDeCaso" label="Numero de Siniestro" required hide-details></v-text-field>
            </v-card-text>
            <v-btn color="grey darken-1 white--text" type="submit">Consultar</v-btn>
            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
          </form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
  <v-dialog v-model="consulta" persistent max-width="800px">
      <v-card>
        <v-card-title primary-title>
          <div class="headline"></div>
        </v-card-title>
        <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex  xs12 sm6 md4>
                  <div class="caption">Numero de Caso</div>
                  <p color="" class="font-weight-bold"> {{this.caso.numeroDeCaso}}</p>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <div class="caption">Nombre del Asegurado</div>
                  <p class="font-weight-bold">{{this.caso.nombreAsegurado}}</p>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <div class="caption">Nombre de Contacto</div>
                  <p class="font-weight-bold">{{this.caso.nombreDeContacto}}</p>
                </v-flex>
                <!--v-flex xs12 sm6 md4>
                  <div class="caption">E-mail</div>
                  <p class="font-weight-bold">{{this.caso.email}}</p>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <div class="caption">Telefono</div>
                  <p class="font-weight-bold">{{this.caso.telefono}}</p>
                </v-flex-->
                <v-flex xs12 sm6 md4>
                  <div class="caption">Estado</div>
                  <p class="font-weight-bold">{{this.caso.estado}}</p>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <div class="caption">Fecha Asignacion</div>
                  <p class="font-weight-bold">{{ formatDate(this.caso.fechaAsignacion)}}</p>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <div class="caption">Fecha Inspeccion</div>
                  <p class="font-weight-bold">{{formatDate(this.caso.fechaInspeccion)}}</p>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <div class="caption">Inicio de Obra</div>
                  <p class="font-weight-bold">{{formatDate(this.caso.inicioDeObra)}}</p>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <div class="caption">Termino de Obra</div>
                  <p class="font-weight-bold">{{formatDate(this.caso.terminoDeObra)}}</p>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <div class="caption">Informe Inicial</div>
                  <a :href="this.caso.informeInicial"><v-icon>picture_as_pdf</v-icon></a>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <div class="caption">Informe Final</div>
                  <a :href="this.caso.informeFinal"><v-icon>picture_as_pdf</v-icon></a>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <div class="caption">Comentarios</div>
                  <p class="font-weight-bold">{{this.caso.observacion}}</p>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <div class="caption">Ultima Actualización </div>
                  <p class="body-1">{{formatDate(this.caso.updatedAt)}}</p>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click="consulta = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</v-content>

<v-content>
  <section>
    <v-layout
      column
      wrap
      class="my-5"
      align-center
      justify-center
    >
      <v-flex xs12 sm4 class="my-4">
        <div class="text-xs-center titulo1">
          <h2>Nuestros Servicios</h2>
          <hr class="linea">
          <span>
           Desarrollamos proyectos de infraestructura, reparaciones y soluciones buscando cumplir con las necesidades de nuestros clientes.
          </span>
        </div>
      </v-flex>
      <v-flex xs12>
        <v-container grid-list-xl>
          <v-layout row wrap>
            <v-flex xs12 md4>
              <div class="col text-xs-center">
                <div class="contenedor">
                  <v-img class="icono1" :src="require('../assets/icons/icono1.svg')" contain max-height=150></v-img>
                  <h2>Construcción</h2>
                </div>
              </div>
            </v-flex>
            <v-flex xs12 md4>
              <div class="col text-xs-center">
                <div class="contenedor">
                  <v-img class="icono1" :src="require('../assets/icons/icono2.svg')" contain max-height=150></v-img>
                  <h2>Remodelaciones</h2>
              </div>
              </div>
            </v-flex>
            <v-flex xs12 md4>
              <div class="col text-xs-center">
                <div class="contenedor">
                  <v-img class="icono1" :src="require('../assets/icons/icono3.svg')" contain max-height=150></v-img>
                  <h2 >Estructuras Metálicas</h2>
                </div>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>

      <v-flex xs12>
        <v-btn href="/servicios" color="red darken-1 white--text">Ver Más</v-btn>
      </v-flex>

     </v-layout>
  </section>
</v-content>

<v-content class="">
  <section center>
    <v-img class="bg-blurrr1">
      <div class="overColor"></div>
    </v-img>
    <v-layout class="white--text bg-text1">
      <v-flex xs12>
        <h2>Sobre Nosotros</h2>
        <p>Somos una empresa líder en el sector de la construcción con un equipo comprometido, evolucionando constantemente nuestros servicios para ofrecer productos innovadores que cumplan las necesidades de los clientes, diseño y conciencia de servicio al cliente que garanticen solidez de la empresa.</p>
        <v-btn href="/nosotros" color="red darken-1 white--text">Leer Más</v-btn>
      </v-flex>
    </v-layout>
  </section>
</v-content>

<Futer />
</v-app>
</template>

<script>
import Nab from '@/views/components/Nav.vue'
import Futer from '@/views/components/Footer.vue'

export default {
  name: 'home',
  components: {
    Nab,
    Futer
  },
  data: () => ({
    email: undefined,
    password: undefined,
    numeroDeCaso: undefined,
    caso: {},
    dialog: false,
    consulta: false,
    loading: false,
    title: 'FM Soluciones',
    emailRules: [
      v => {
        return !!v || 'E-mail es requerido'
      },
      v =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        'E-mail must be valid'
    ]
  }),
  methods: {
    query () {
      this.axios.get(`/casos/public/${this.numeroDeCaso}`).then(
        result => {
          if (result.data.length > 0) {
            this.caso = result.data[0]
            this.consulta = true
          } else {
            alert('No hay coincidencias.')
          }
        },
        error => console.log(error)
      )
    },
    formatDate (date) {
      if (!date) return null

      date = date.slice(0, 10)
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    }
  }
}
</script>

<style scoped>
.finedTitle {
  font-weight: 900;
  text-shadow: 2px 2px #000000;
}
.social-icon {
  font-size: 21px;
  color: black;
}
.overColor{
  background: rgba(0,0,0,0.5);
  z-index: 1;
  overflow: hidden;
  height: 300px;
  width: auto;
}
.overColor2{
  background: rgba(0,0,0,0.5);
  z-index: 1;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.bg-blurrr{
  background-image: url("../assets/imagen-3.jpg");
  background-size: cover;
  filter: blur(2px);
  -webkit-filter: blur(2px);
}
.bg-blurrr1{
  z-index: 0;
  overflow: hidden;
  background-image: url("../assets/imagen.4.jpg");
  background-size: cover;
  width: auto;
  max-height: 300px;
}
.bg-text{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
/*  width: 90%;*/
  padding: 5px;
  text-align: center;
}
.bg-text1{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 90%;
  padding: 5px;
  text-align: center;
}
.titulo1 h2{
  letter-spacing: 2px;
  color: grey;
  font-weight: 500;
  font-size: 25px;
}
.titulo1 span{
  font-size: 14px;
  color: #515151;

}

.search{
  -webkit-box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.75);
}
.linea{
  border: 2px solid #cb0e11;
  width: 150px;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
  margin-top: 8px;
}
.contenedor{
  width: 250px;
  height: 250px;
  background-color:#efefee;
  padding: 15px;
  margin: 15px;
  box-shadow: 7px 7px 8px #88888829;
}
.contenedor h2{
  color: #5e5e5e;
  font-size: 16px;
  font-weight: 500;
}
.col{
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.icono1{
  width: 80px;
  margin: 25px auto;
  display: block;
}
@media(max-width: 720px){
  .search{
    padding: 0px;
    margin: 2px;
    width: 300px;
  }
  .col{
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
}
@media(max-width: 500px){
  .search{
    padding: 0px;
    margin: 2px;
    width: 300px;
  }
  .col{
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
}
@media(max-width: 425px){
  .search{
    padding: 0px;
    margin: 2px;
    width: 300px;
  }
  .col{
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  }
  .bg-blurrr1{
  max-height: 600px;
  }
}
</style>
