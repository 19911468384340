<template>
  <v-toolbar dark height="100" style="background-color: rgba(185, 5, 4,0.6);">
    <template>
      <v-dialog persistent v-model="dialog" max-width="600px">
        <template v-slot:activator="{ on }">
          <v-btn depressed class="blue lighten-3 black--text" v-on="on">Acceder</v-btn>
        </template>
      <form method="POST" @submit.prevent="handleSubmit">
        <v-card>
          <v-card-title primary-title>
            <div class="headline">Iniciar Sesion</div>
          </v-card-title>
          <v-card-text>
          <!--div v-if="alert.message" :class="`alert ${alert.type}`">{{alert.message}}</div-->
            <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field v-model="email" label="Email" placeholder="Ingresa tu Email" type="email" required></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field v-model="password" label="Contraseña" placeholder="Ingresa tu contraseña." type="password" required></v-text-field>
                  </v-flex>
                </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" flat v-on:click="dialog = false">Cerrar</v-btn>
            <v-btn color="blue darken-1" type="submit" flat >Ingresar</v-btn>
          </v-card-actions>
        </v-card>
      </form>
      </v-dialog>
      </template>
    <v-img class="logo" :src="require('@/assets/logo-blanco-1.png')" alt="FM Soluciones" contain max-height=90></v-img>
    <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          v-for="item in menu"
          :key="item.icon"
          :to="item.url"
          flat
        >{{ item.title }}</v-btn>
    </v-toolbar-items>
    <v-menu class="hidden-md-and-up">
      <v-toolbar-side-icon slot="activator"></v-toolbar-side-icon>
      <v-list>
        <v-list-tile v-for="item in menu" :key="item.icon">
          <v-list-tile-content>
            <v-btn :to="item.url" flat>{{ item.title }}</v-btn>
           </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-menu>
  </v-toolbar>
  <!--v-toolbar app>
  <v-toolbar-items class>
    <v-toolbar class="" card center>
    </v-toolbar>
    <v-btn flat href="/">Inicio</v-btn>
    <v-btn flat href="/nosotros">Nosotros</v-btn>
    <v-btn flat href="/servicios">Servicios</v-btn>
    <v-btn flat href="/proyectos">Proyectos</v-btn>
    <v-btn flat href="/contactenos">Contáctenos</v-btn>
  </v-toolbar-items>
</v-toolbar-->
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'nab',
  data: () => ({
    email: '',
    password: '',
    numeroDeCaso: '',
    dialog: false,
    loading: false,
    emailRules: [
      v => {
        return !!v || 'E-mail is required'
      },
      v =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        'E-mail must be valid'
    ],
    menu: [
      { icon: 'home', title: 'Inicio', url: '/' },
      { icon: 'info-circle', title: 'Nosotros', url: '/nosotros' },
      { icon: 'hand-holding', title: 'Servicios', url: '/servicios' },
      { icon: 'image', title: 'Proyectos', url: '/proyectos' },
      { icon: 'envelope', title: 'Contactenos', url: '/contactenos' }
    ]
  }),
  computed: {
    ...mapState('account', ['status'])
  },
  methods: {
    ...mapActions('account', ['login', 'logout']),
    handleSubmit (e) {
      this.submitted = true
      const { email, password } = this
      if (email && password) {
        this.login({ email, password })
      }
    }
    /* login: function () {
      var user = {
        email: this.email,
        password: this.password
      }
      this.axios.post('/login', user).then(
        result => {
          this.dialog = false
          if (result.data.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(result.data))
          }
          // Chequeo de rol
          if (result.data.role === 'Administrador') {
            this.$router.replace('admin/proyectos')
          } else if (result.data.role === 'Manager') {
            this.$router.replace('manager/proyectos')
          } else {
            this.$router.replace('sucursal/proyectos')
          }
        },
        error => {
          alert(error.body.message)
        }
      )
    } */
  }
}
</script>

<style scoped>
.v-toolbar__items .v-btn {
  font-size: 12px;
  font-weight: 900;
  max-height: 40px;
}
.v-btn{
  padding:0 10px!important;
}
.logo{
  margin-left: 380px;
}
@media screen and (max-width:1024px){
  .logo{
    margin: 0 80px 0 0px;
  }
}
@media screen and (max-width:960px){
  .logo{
    margin: 0 80px 0 0px;
  }
  .v-toolbar__items .v-btn {
  font-size: 12px;
  font-weight: 800;
  max-height: 40px;
  }
  .v-btn{
  padding:0px 8px!important;
  }
}
@media screen and (max-width:768px){
  .logo{
    margin: 0 80px 0 0px;
  }

}

@media screen and (max-width:425px){
  .logo{
    margin: 0 40px 0 0px;
  }
}

@media (max-width:375px){
  .logo{
    margin: 0 40px 0 0px;
  }
}
</style>
