import { cotizacionService } from '../_services'

const state = {
  all: []
}

const actions = {
  cargarCotizaciones ({ commit }) {
    commit('getAllRequest')

    cotizacionService.getAll()
      .then(
        result => commit('getAllSuccess', result.data),
        error => commit('getAllFailure', error)
      )
  },

  creaCotizacion ({ dispatch, commit }, cotizacion) {
    commit('registerRequest', cotizacion)

    cotizacionService.register(cotizacion)
      .then(
        cotizacionx => {
          commit('registerSuccess', cotizacionx)
          setTimeout(() => {
            // display success message after route change completes
            dispatch('alerts/success', 'Registration successful', { root: true })
          })
        },
        error => {
          commit('registerFailure', error)
          dispatch('alerts/error', { message: error }, { root: true })
        }
      )
  },

  dilitCotizacion ({ commit }, id) {
    commit('deleteRequest', id)

    cotizacionService.delete(id)
      .then(
        cotizacion => commit('deleteSuccess', id),
        error => commit('deleteFailure', { id, error: error.toString() })
      )
  },

  updeitCotizacion ({ commit }, cotizacion) {
    commit('updateRequest', cotizacion.id)
    cotizacionService.update(cotizacion)
      .then(
        cotizacion => commit('updateSuccess', cotizacion),
        error => commit('updateFailure', { cotizacion, error: error.toString() })
      )
  }
}

const mutations = {
  getAllRequest (state) {
    state.all = { loading: true }
  },
  getAllSuccess (state, cotizaciones) {
    state.all = { items: cotizaciones }
  },
  getAllFailure (state, error) {
    state.all = { error }
  },
  registerRequest (state, cotizacion) {
    state.status = { registering: true }
  },
  registerSuccess (state, cotizacion) {
    state.all.items.push(cotizacion)
  },
  registerFailure (state, error) {
    state.status = {}
    console.log(error)
  },
  deleteRequest (state, id) {
    // add 'deleting:true' property to cotizacion being deleted
    state.all.items = state.all.items.map(cotizacion =>
      cotizacion.id === id
        ? { ...cotizacion, deleting: true }
        : cotizacion
    )
  },
  deleteSuccess (state, id) {
    // remove deleted cotizacion from state
    state.all.items = state.all.items.filter(cotizacion => cotizacion.id !== id)
  },
  deleteFailure (state, { id, error }) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to cotizacion
    state.all.items = state.all.items.map(cotizacion => {
      if (cotizacion.id === id) {
        // make copy of cotizacion without 'deleting:true' property
        const { deleting, ...cotizacionCopy } = cotizacion
        // return copy of cotizacion with 'deleteError:[error]' property
        return { ...cotizacionCopy, deleteError: error }
      }

      return cotizacion
    })
    console.log(error)
  },
  updateRequest (state, id) {
    // add 'updating:true' property to cotizacion being updated
    state.all.items = state.all.items.map(cotizacion =>
      cotizacion.id === id
        ? { ...cotizacion, updating: true }
        : cotizacion
    )
  },
  updateSuccess (state, userx) {
    // update cotizacion in state
    const item = state.all.items.find(cotizacion => cotizacion.id === userx.id)
    Object.assign(item, userx)
  },
  updateFailure (state, { id, error }) {
    // remove 'updating:true' property and add 'updateError:[error]' property to cotizacion
    state.all.items = state.map(cotizacion => {
      if (cotizacion.id === id) {
        // make copy of cotizacion without 'updating:true' property
        const { updating, ...cotizacionCopy } = cotizacion
        // return copy of cotizacion with 'updateError:[error]' property
        return { ...cotizacionCopy, updateError: error }
      }

      return cotizacion
    })
  }
}

export const cotizaciones = {
  namespaced: true,
  state,
  mutations,
  actions
}
