import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { authHeader } from '../_helpers'

Vue.use(VueAxios, axios)

export const eventosService = {
  getAll,
  register,
  getById,
  update,
  delete: _delete
}

function getAll () {
  return axios.get('/eventos', { ...authHeader() })
}

function register (evento) {
  return axios.post('/eventos/crear', evento, { ...authHeader() }).then(handleResponse)
}

function getById (id) {
  return axios.get(`/eventos/${id}`, { ...authHeader() }).then(handleResponse)
}

function update (evento) {
  return axios.put(`/eventos/${evento.id}`, evento, { ...authHeader() }).then(handleResponse)
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete (id) {
  return axios.delete(`/eventos/${id}`, { ...authHeader() }).then(handleResponse)
}

function handleResponse (result) {
  if (result.statusText !== 'OK') {
    if (result.status === 401) {
      // auto logout if 401 result returned from api
      this.$store.dispatch('account/logout')
    }

    return result.error
  }

  return result.data
}
