import { maestroService } from '../_services'

const state = {
  all: []
}

const actions = {
  cargarMaestros ({ commit }) {
    commit('getAllRequest')

    maestroService.getAll()
      .then(
        result => commit('getAllSuccess', result.data),
        error => commit('getAllFailure', error)
      )
  },

  creaMaestro ({ dispatch, commit }, maestro) {
    commit('registerRequest', maestro)

    maestroService.register(maestro)
      .then(
        maestrox => {
          commit('registerSuccess', maestrox)
          setTimeout(() => {
            // display success message after route change completes
            dispatch('alerts/success', 'Registration successful', { root: true })
          })
        },
        error => {
          commit('registerFailure', error)
          dispatch('alerts/error', { message: error }, { root: true })
        }
      )
  },

  dilitMaestro ({ commit }, id) {
    commit('deleteRequest', id)

    maestroService.delete(id)
      .then(
        maestro => commit('deleteSuccess', id),
        error => commit('deleteFailure', { id, error: error.toString() })
      )
  },

  updeitMaestro ({ commit }, maestro) {
    commit('updateRequest', maestro.id)
    maestroService.update(maestro)
      .then(
        maestro => commit('updateSuccess', maestro),
        error => commit('updateFailure', { maestro, error: error.toString() })
      )
  }
}

const mutations = {
  getAllRequest (state) {
    state.all = { loading: true }
  },
  getAllSuccess (state, maestros) {
    state.all = { items: maestros }
  },
  getAllFailure (state, error) {
    state.all = { error }
  },
  registerRequest (state, maestro) {
    state.status = { registering: true }
  },
  registerSuccess (state, maestro) {
    state.all.items.push(maestro)
  },
  registerFailure (state, error) {
    state.status = {}
    console.log(error)
  },
  deleteRequest (state, id) {
    // add 'deleting:true' property to maestro being deleted
    state.all.items = state.all.items.map(maestro =>
      maestro.id === id
        ? { ...maestro, deleting: true }
        : maestro
    )
  },
  deleteSuccess (state, id) {
    // remove deleted maestro from state
    state.all.items = state.all.items.filter(maestro => maestro.id !== id)
  },
  deleteFailure (state, { id, error }) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to maestro
    state.all.items = state.all.items.map(maestro => {
      if (maestro.id === id) {
        // make copy of maestro without 'deleting:true' property
        const { deleting, ...maestroCopy } = maestro
        // return copy of maestro with 'deleteError:[error]' property
        return { ...maestroCopy, deleteError: error }
      }

      return maestro
    })
    console.log(error)
  },
  updateRequest (state, id) {
    // add 'updating:true' property to maestro being updated
    state.all.items = state.all.items.map(maestro =>
      maestro.id === id
        ? { ...maestro, updating: true }
        : maestro
    )
  },
  updateSuccess (state, userx) {
    // update maestro in state
    const item = state.all.items.find(maestro => maestro.id === userx.id)
    Object.assign(item, userx)
  },
  updateFailure (state, { id, error }) {
    // remove 'updating:true' property and add 'updateError:[error]' property to maestro
    state.all.items = state.map(maestro => {
      if (maestro.id === id) {
        // make copy of maestro without 'updating:true' property
        const { updating, ...maestroCopy } = maestro
        // return copy of maestro with 'updateError:[error]' property
        return { ...maestroCopy, updateError: error }
      }

      return maestro
    })
  }
}

export const maestros = {
  namespaced: true,
  state,
  mutations,
  actions
}
