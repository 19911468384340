import Vue from 'vue'
import Vuex from 'vuex'

import { alerts } from './alert.module'
import { account } from './account.module'
import { casos } from './casos.module'
import { cotizaciones } from './cotizacion.module'
import { maestros } from './maestro.module'
import { eventos } from './evento.module'
import { users } from './users.module'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    alerts,
    account,
    casos,
    cotizaciones,
    maestros,
    eventos,
    users
  }
})
