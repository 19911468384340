<template>
  <v-container>
    <v-toolbar flat color="white">
      <v-toolbar-title>Casos Abiertos</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Busqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="this.casosFiltered"
      :search="search"
      class="elevation-1"
      disable-initial-sort
    >
      <template v-slot:items="props">
        <td>{{ props.item.numeroDeCaso }}</td>
        <td class="text-xs-right">{{ props.item.sucursal }}</td>
        <td class="text-xs-right">{{ props.item.nombreDeContacto }}</td>
        <td class="text-xs-right">{{ props.item.telefono2 }}</td>
        <td class="text-xs-right">{{ props.item.estado }}</td>
        <td class="text-xs-right">{{ formatDate(props.item.fechaAsignacion) }}</td>
        <td class="text-xs-right">{{ formatDate(props.item.fechaInspeccion) }}</td>
        <td class="text-xs-right">{{ formatDate(props.item.inicioDeObra) }}</td>
        <td class="text-xs-right">{{ formatDate(props.item.terminoDeObra) }}</td>
        <td class="text-xs-right">{{ props.item.observacion }}</td>
      </template>
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="warning">
          No hay resultados para: "{{ search }}".
        </v-alert>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    search: '',
    headers: [
      {
        text: 'Número de Caso',
        align: 'left',
        sortable: true,
        value: 'numeroDeCaso'
      },
      { text: 'Sucursal', value: 'sucursal' },
      { text: 'Nombre Contacto', value: 'nombreDeContacto' },
      { text: 'Telefono', value: 'telefono2' },
      { text: 'Estado', value: 'estado' },
      { text: 'Fecha Asignación', value: 'fechaAsignacion' },
      { text: 'Fecha Inspección', value: 'fechaInspeccion' },
      { text: 'Inicio de Obra', value: 'inicioDeObra' },
      { text: 'Término de Obra', value: 'terminoDeObra' },
      { text: 'Observación', value: 'observacion' }
    ]
  }),
  computed: {
    ...mapGetters({
      casosFiltered: 'casos/activos'
    })
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      date = date.substring(0, 10)
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    }
  }
}
</script>

<style>

</style>
