import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { authHeader } from '../_helpers'

Vue.use(VueAxios, axios)

export const maestroService = {
  getAll,
  register,
  getById,
  update,
  delete: _delete
}

function getAll () {
  return axios.get('/maestros', { ...authHeader() })
}

function register (maestro) {
  return axios.post('/maestros/crear', maestro, { ...authHeader() }).then(handleResponse)
}

function getById (id) {
  return axios.get(`/maestros/${id}`, { ...authHeader() }).then(handleResponse)
}

function update (maestro) {
  return axios.put(`/maestros/${maestro.id}`, maestro, { ...authHeader() }).then(handleResponse)
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete (id) {
  return axios.delete(`/maestros/${id}`, { ...authHeader() }).then(handleResponse)
}

function handleResponse (result) {
  if (result.statusText !== 'OK') {
    if (result.status === 401) {
      // auto logout if 401 result returned from api
      this.$store.dispatch('account/logout')
    }

    return result.error
  }

  return result.data
}
