<template>
  <div>
    <v-app v-if="this.$route.name === 'home'">
      <v-content>
      <v-snackbar
        top
        clearable
        v-for="(alert, index) in alerts.filter(s => s.showing)"
        :key="alert.message + Math.random()"
        :style="`top: ${index * 40}px`"
        v-model="alert.showing"
        :timeout="2000"
        :color="alert.type != 'alert-success' ? 'error' : 'success'">
        <span>{{alert.message}}</span>
        <v-btn color="white" flat @click="alert.showing = false">Cerrar</v-btn>
      </v-snackbar>
        <router-view/>
      </v-content>
    </v-app>
    <v-app v-else>
      <v-content class="text-xs-center" grid-list-lg>
        <v-snackbar
        top
        clearable
        v-for="(alert, index) in alerts.filter(s => s.showing)"
        :key="alert.message + Math.random()"
        :style="`top: ${index * 20}px`"
        v-model="alert.showing"
        :timeout="2000"
        :color="alert.type != 'alert-success' ? 'error' : 'success'">
        <span>{{alert.message}}</span>
        <v-btn color="white" flat @click="alert.showing = false">Cerrar</v-btn>
        </v-snackbar>
        <router-view/>
      </v-content>
    </v-app>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'app',
  computed: {
    ...mapState({
      alerts: state => state.alerts.all
    })
  }
  /* ,
  methods: {
    ...mapActions({
      clearAlert: 'alerts/clear'
    })
  }
   watch: {
    $route (to, from) {
      // clear alert on location change
      this.clearAlert()
    }
  } */
}
</script>

<style>
  html, body {
    height: 100%;
    background-color: #ddd8d4;
  }
  #app {
    font-family: 'Poppins';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    letter-spacing: 1px;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
  }
</style>
