<template>
  <v-container>
    <v-toolbar flat color="white">
      <v-toolbar-title>Casos 2020</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Busqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-toolbar>
    <v-data-table
      rows-per-page-text="Casos por pagina"
      v-model="selected"
      select-all
      ref="dataTable"
      :headers="headers"
      :items="this.casosFiltered"
      :search="search"
      class="elevation-1"
      disable-initial-sort
    >
      <template v-slot:items="props">
        <tr :style="{backgroundColor: (props.item.estado == 'En Ejecución' ? '#FFF176' : 'transparent' ) }">
        <td>
          <v-checkbox
            v-model="props.selected"
            primary
            hide-details
          ></v-checkbox>
        </td>
        <td>{{ props.item.numeroDeCaso }}</td>
        <td class="justify-center"><a :href="props.item.informeInicial"><v-icon>picture_as_pdf</v-icon></a></td>
        <td class="justify-center"><a :href="props.item.informeFinal"><v-icon>picture_as_pdf</v-icon></a></td>
        <td class="text-xs-right">{{ props.item.sucursal }}</td>
        <td class="text-xs-right">{{ props.item.origen }}</td>
        <td class="text-xs-right">{{ props.item.nombreAsegurado }}</td>
        <td class="text-xs-right">{{ props.item.email }}</td>
        <td class="text-xs-right">{{ props.item.telefono1 }}</td>
        <td class="text-xs-right">{{ props.item.direccion }}</td>
        <td class="text-xs-right">{{ props.item.comuna }}</td>
        <td class="text-xs-right">{{ props.item.estado }}</td>
        <td class="text-xs-right">{{ formatDate(props.item.fechaAsignacion) }}</td>
        <td class="text-xs-right">{{ props.item.observacion }}</td>
        </tr>
      </template>
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="warning">
          No hay resultados para: "{{ search }}".
        </v-alert>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import XLSX from 'xlsx'

export default {
  data: () => ({
    search: '',
    selected: [],
    sucursales: ['Valparaiso', 'Santiago', 'Otro'],
    origenes: ['Liberty', 'Banco Estado'],
    states: ['Asignado', 'En Inspección', 'Esperando Aprobación', 'En Ejecución', 'Rechazado', 'Sin Respuesta', 'Indemnizacion', 'Finalizado'],
    headers: [
      {
        text: 'Número de Caso',
        align: 'left',
        sortable: true,
        value: 'numeroDeCaso'
      },
      { text: 'Inf. Inicial', value: 'informeInicial', sortable: false },
      { text: 'Inf. Final', value: 'informeFinal', sortable: false },
      { text: 'Acciones', value: 'name', sortable: false },
      { text: 'Sucursal', value: 'sucursal' },
      { text: 'Origen', value: 'origen' },
      { text: 'Nombre Asegurado', value: 'nombreAsegurado' },
      { text: 'Email', value: 'email' },
      { text: 'Telefono 1', value: 'telefono1' },
      { text: 'Direccion', value: 'direccion' },
      { text: 'Comuna', value: 'comuna' },
      { text: 'Estado', value: 'estado' },
      { text: 'Fecha Asignación', value: 'fechaAsignacion' },
      { text: 'Observación', value: 'observacion' }
    ],
    editedIndex: -1,
    editedItem: {
      numeroDeCaso: undefined,
      nombreAsegurado: undefined,
      nombreDeContacto: undefined,
      email: undefined,
      telefono1: undefined,
      telefono2: undefined,
      direccion: undefined,
      comuna: undefined,
      estado: undefined,
      sucursal: undefined,
      origen: undefined,
      fechaAsignacion: undefined,
      fechaInspeccion: undefined,
      inicioDeObra: undefined,
      terminoDeObra: undefined,
      observacion: undefined,
      informeInicial: undefined,
      informeFinal: undefined
    },
    defaultItem: {
      numeroDeCaso: undefined,
      nombreAsegurado: undefined,
      nombreDeContacto: undefined,
      email: undefined,
      telefono1: undefined,
      telefono2: undefined,
      direccion: undefined,
      comuna: undefined,
      estado: undefined,
      sucursal: undefined,
      origen: undefined,
      fechaAsignacion: undefined,
      fechaInspeccion: undefined,
      inicioDeObra: undefined,
      terminoDeObra: undefined,
      observacion: undefined,
      informeInicial: undefined,
      informeFinal: undefined
    },
    casos: undefined,
    casosFiltered: []
  }),
  computed: {
    ...mapGetters({
      casosValpo: 'casos/valpo'
    })
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      date = date.substring(0, 10)
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    filterCases () {
      var date = new Date().getFullYear()
      this.casosValpo.forEach(caso => {
        if (caso.fechaAsignacion !== undefined || null) {
          if (caso.fechaAsignacion.substring(0, 4) === date) {
            this.casosFiltered.push(caso)
          }
        }
      })
    },
    exportXLSX () {
      if (this.selected.length > 1) {
        const data = XLSX.utils.json_to_sheet(this.selected,
          {
            header: ['numeroDeCaso', 'nombreAsegurado', 'nombreDeContacto', 'email', 'telefono1', 'telefono2', 'direccion', 'comuna', 'estado', 'sucursal', 'origen', 'observacion']
          })
        data.A1.v = 'Numero de Caso'
        data.B1.v = 'Nombre de Asegurado'
        data.C1.v = 'Nombre de Contacto'
        data.D1.v = 'E-mail'
        data.E1.v = 'Telefono1'
        data.F1.v = 'Telefono2'
        data.G1.v = 'Direccion'
        data.H1.v = 'Comuna'
        data.I1.v = 'Estado'
        data.J1.v = 'Sucursal'
        data.K1.v = 'Origen'
        data.L1.v = 'Observacion'
        const workbook = XLSX.utils.book_new()
        const filename = 'fmsoluciones'
        XLSX.utils.book_append_sheet(workbook, data, filename)
        XLSX.writeFile(workbook, `${filename}.xlsx`)
      } else if (this.$refs.dataTable.filteredItems.length !== this.$refs.dataTable.itemsLength) {
        var sure = confirm(`Esta seguro que desea exportar los ${this.$refs.dataTable.filteredItems.length} casos de esta pagina?`)
        if (sure === true) {
          const data = XLSX.utils.json_to_sheet(this.$refs.dataTable.filteredItems,
            {
              header: ['numeroDeCaso', 'nombreAsegurado', 'nombreDeContacto', 'email', 'telefono1', 'telefono2', 'direccion', 'comuna', 'estado', 'sucursal', 'origen', 'observacion']
            })
          data.A1.v = 'Numero de Caso'
          data.B1.v = 'Nombre de Asegurado'
          data.C1.v = 'Nombre de Contacto'
          data.D1.v = 'E-mail'
          data.E1.v = 'Telefono1'
          data.F1.v = 'Telefono2'
          data.G1.v = 'Direccion'
          data.H1.v = 'Comuna'
          data.I1.v = 'Estado'
          data.J1.v = 'Sucursal'
          data.K1.v = 'Origen'
          data.L1.v = 'Observacion'
          const workbook = XLSX.utils.book_new()
          const filename = 'fmsoluciones'
          XLSX.utils.book_append_sheet(workbook, data, filename)
          XLSX.writeFile(workbook, `${filename}.xlsx`)
          alert('Archivo Exportado')
        } else {
          alert('No se exporto nada')
        }
      } else if (this.$refs.dataTable.filteredItems.length === this.$refs.dataTable.itemsLength) {
        const data = XLSX.utils.json_to_sheet(this.$refs.dataTable.filteredItems,
          {
            header: ['numeroDeCaso', 'nombreAsegurado', 'nombreDeContacto', 'email', 'telefono1', 'telefono2', 'direccion', 'comuna', 'estado', 'sucursal', 'origen', 'observacion']
          })
        data.A1.v = 'Numero de Caso'
        data.B1.v = 'Nombre de Asegurado'
        data.C1.v = 'Nombre de Contacto'
        data.D1.v = 'E-mail'
        data.E1.v = 'Telefono1'
        data.F1.v = 'Telefono2'
        data.G1.v = 'Direccion'
        data.H1.v = 'Comuna'
        data.I1.v = 'Estado'
        data.J1.v = 'Sucursal'
        data.K1.v = 'Origen'
        data.L1.v = 'Observacion'
        const workbook = XLSX.utils.book_new()
        const filename = 'fmsoluciones'
        XLSX.utils.book_append_sheet(workbook, data, filename)
        XLSX.writeFile(workbook, `${filename}.xlsx`)
      } else {
        const data = XLSX.utils.json_to_sheet(this.casosFiltered,
          {
            header: ['numeroDeCaso', 'nombreAsegurado', 'nombreDeContacto', 'email', 'telefono1', 'telefono2', 'direccion', 'comuna', 'estado', 'sucursal', 'origen', 'observacion']
          })
        data.A1.v = 'Numero de Caso'
        data.B1.v = 'Nombre de Asegurado'
        data.C1.v = 'Nombre de Contacto'
        data.D1.v = 'E-mail'
        data.E1.v = 'Telefono1'
        data.F1.v = 'Telefono2'
        data.G1.v = 'Direccion'
        data.H1.v = 'Comuna'
        data.I1.v = 'Estado'
        data.J1.v = 'Sucursal'
        data.K1.v = 'Origen'
        data.L1.v = 'Observacion'
        const workbook = XLSX.utils.book_new()
        const filename = 'fmsoluciones'
        XLSX.utils.book_append_sheet(workbook, data, filename)
        XLSX.writeFile(workbook, `${filename}.xlsx`)
      }
    },
    uploadFile () {
      const file = this.$refs.fileInput.files[0]

      if (!file) {
        console.error('no file selected')
        return
      }

      const formData = new FormData()

      formData.append('file', file)

      return this.axios.post(
        '/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(result => {
        this.editedItem.informeInicial = result.body.fileUrl
        console.info('file uploaded successfully')
      }).catch(error => {
        console.error('file upload failed', error)
      })
    },
    uploadFile2 () {
      const file = this.$refs.fileInput2.files[0]

      if (!file) {
        console.error('no file selected')
        return
      }

      const formData = new FormData()

      formData.append('file', file)

      return this.axios.post(
        '/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(result => {
        this.editedItem.informeFinal = result.body.fileUrl
        console.info('file uploaded successfully')
      }).catch(error => {
        console.error('file upload failed', error)
      })
    },
    save () {
      var caso = {
        numeroDeCaso: this.editedItem.numeroDeCaso,
        nombreAsegurado: this.editedItem.nombreAsegurado,
        nombreDeContacto: this.editedItem.nombreDeContacto,
        email: this.editedItem.email,
        telefono1: this.editedItem.telefono1,
        telefono2: this.editedItem.telefono2,
        direccion: this.editedItem.direccion,
        comuna: this.editedItem.comuna,
        estado: this.editedItem.estado,
        sucursal: this.editedItem.sucursal,
        origen: this.editedItem.origen,
        fechaAsignacion: this.editedItem.fechaAsignacion,
        fechaInspeccion: this.editedItem.fechaInspeccion,
        inicioDeObra: this.editedItem.inicioDeObra,
        terminoDeObra: this.editedItem.terminoDeObra,
        informeInicial: this.editedItem.informeInicial,
        informeFinal: this.editedItem.informeFinal,
        observacion: this.editedItem.observacion
      }
      var visita = {
        numeroDeCaso: this.editedItem.numeroDeCaso,
        nombreDeContacto: this.editedItem.nombreDeContacto,
        telefono: this.editedItem.telefono2,
        fechaAsignacion: this.editedItem.fechaAsignacion,
        fechaInspeccion: this.editedItem.fechaInspeccion,
        inicioDeObra: this.editedItem.inicioDeObra,
        terminoDeObra: this.editedItem.terminoDeObra,
        observacion: this.editedItem.observacion
      }
      if (this.editedIndex === -1) {
        this.$http.post(this.$cfg.base + '/caso', caso).then(
          () => {
            this.saveVisita(visita)
            this.close()
            this.get()
          },
          error => console.log(error)
        )
      } else {
        this.$http.put(`${this.$cfg.base}/caso/${this.editedItem.id}`, caso).then(
          () => {
            this.updateVisita(visita)
            this.close()
            this.get()
          },
          error => console.log(error)
        )
      }
    }
  }
}
</script>
