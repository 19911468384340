<template>
  <nav>
    <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Agregaste un Proyecto!.</span>
      <v-btn color="white" flat @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-toolbar flat app>
      <v-toolbar-side-icon @click="drawer = !drawer" class="grey--text"></v-toolbar-side-icon>
      <v-toolbar-title class="text-uppercase grey--text">
        <span>FM</span>
        <span class="font-weight-light">Soluciones</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn flat color="grey" @click="logout">
        <span>Salir</span>
        <v-icon right>exit_to_app</v-icon>
      </v-btn>
    </v-toolbar>

    <v-navigation-drawer app v-model="drawer" class="primary">
      <v-layout column align-center>
        <v-flex class="mt-5">
          <v-img :src="require('@/assets/logo_nav.png')" alt="FM Soluciones"></v-img>
          <p class="white--text subheading mt-1">Soluciones <br>Integrales</p>
        </v-flex>
      </v-layout>
      <v-list>
        <v-list-tile v-for="link in links" :key="link.text" router :to="link.route">
          <v-list-tile-action>
            <v-icon class="white--text">{{ link.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title class="white--text">{{ link.text }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>

  </nav>
</template>

<script>
export default {
  data () {
    return {
      drawer: false,
      links: [
        { icon: 'folder', text: 'Proyectos', route: '/sucursal/proyectos' },
        { icon: 'folder', text: 'Proyectos 2020', route: '/sucursal/proyectos-2020' },
        { icon: 'insert_chart', text: 'Estadisticas', route: '/sucursal/estadisticas' }
      ],
      snackbar: false
    }
  },
  methods: {
    logout () {
      localStorage.removeItem('user')
      this.$router.replace('/')
    }
  },
  beforeCreate () {
    document.title = 'FM Soluciones | Admin'
  }
}
</script>

<style>
</style>
