import Vue from 'vue'
import Router from 'vue-router'
// import goTo from 'vuetify/lib/components/Vuetify/goTo'

// public
import Home from '../views/Home.vue'
import Nosotros from '../views/Nosotros.vue'
import Servicios from '../views/Servicios.vue'
import Proyectos from '../views/Proyectos.vue'
import Contacto from '../views/Contacto.vue'
// Admin
import Admin from '../views/Admin.vue'
import ProyectosAdmin from '../views/components/admin/ProyectosAdmin.vue'
import Proyectos2020 from '../views/components/admin/Proyectos2020.vue'
import Proyectos2021 from '../views/components/admin/Proyectos2021.vue'
import Activos from '../views/components/admin/Activos.vue'
import Agenda from '../views/components/admin/Agenda.vue'
import Estadisticas from '../views/components/admin/Estadisticas.vue'
import Encuesta from '../views/components/admin/Encuesta.vue'
import Usuarios from '../views/components/admin/Usuarios.vue'
import PDFviewer from '../views/components/admin/PDFviewer.vue'
import Maestros from '../views/components/admin/Maestros.vue'
// Manager
import Manager from '../views/Manager.vue'
import ProyectosManager from '../views/components/manager/Proyectos1.vue'
import ProyectosM2020 from '../views/components/manager/Proyectos2020.vue'
import ProyectosM2021 from '../views/components/manager/Proyectos2021.vue'
import ActivosM from '../views/components/manager/Activos.vue'
import Estadisticas1 from '../views/components/manager/Estadisticas.vue'
// Sucursal
import Sucursal from '../views/Sucursal.vue'
import ProyectosSucursal from '../views/components/sucursal/Proyectos2.vue'
import ProyectosS2020 from '../views/components/sucursal/Proyectos2020.vue'
import Estadisticas2 from '../views/components/sucursal/Estadisticas.vue'
import { Role } from '@/_helpers/role'

Vue.use(Router)

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/nosotros',
      name: 'nosotros',
      component: Nosotros
    },
    {
      path: '/servicios',
      name: 'servicios',
      component: Servicios
    },
    {
      path: '/proyectos',
      name: 'proyectos',
      component: Proyectos
    },
    {
      path: '/contactenos',
      name: 'contactenos',
      component: Contacto
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      meta: {
        requiresAuth: true,
        authorize: [Role.Admin]
      },
      children: [
        {
          path: 'proyectos',
          component: ProyectosAdmin
        },
        {
          path: 'proyectos-2021',
          component: Proyectos2021
        },
        {
          path: 'proyectos-2020',
          component: Proyectos2020
        },
        {
          path: 'activos',
          component: Activos
        },
        {
          path: 'agenda',
          component: Agenda
        },
        {
          path: 'usuarios',
          component: Usuarios
        },
        {
          path: 'encuesta',
          component: Encuesta
        },
        {
          path: 'estadisticas',
          component: Estadisticas
        },
        {
          path: 'informes',
          component: PDFviewer
        },
        {
          path: 'maestros',
          component: Maestros
        }
      ]
    },
    {
      path: '/manager',
      name: 'manager',
      component: Manager,
      meta: {
        requiresAuth: true,
        authorize: [Role.Manager]
      },
      children: [
        {
          path: 'proyectos',
          component: ProyectosManager
        },
        {
          path: 'proyectos-2020',
          component: ProyectosM2020
        },
        {
          path: 'proyectos-2021',
          component: ProyectosM2021
        },
        {
          path: 'activos',
          component: ActivosM
        },
        {
          path: 'estadisticas',
          component: Estadisticas1
        }
      ]
    },
    {
      path: '/sucursal',
      name: 'sucursal',
      component: Sucursal,
      meta: {
        requiresAuth: true,
        authorize: [Role.Sucursal]
      },
      children: [
        {
          path: 'proyectos',
          component: ProyectosSucursal
        },
        {
          path: 'proyectos-2020',
          component: ProyectosS2020
        },
        {
          path: 'estadisticas',
          component: Estadisticas2
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/', '/nosotros', '/servicios', '/proyectos', '/contactenos']
  const authRequired = !publicPages.includes(to.path)
  const currentUser = localStorage.getItem('user')
  const { authorize } = to.meta
  if (authorize) {
    if (authRequired && !currentUser) {
      // not logged in so redirect to login page with the return url
      return next('/')
    }
    // check if route is restricted by role
    if (authorize.length && !authorize.includes(currentUser.role)) {
      // role not authorised so redirect to home page
      return next('/')
    }
  }
  next()
})
