<template>
  <v-container fluid grid-list-md fill-height class="ma-0 pa-2">
    <v-layout row wrap>
      <v-flex xs12>
        <v-layout align-center justify-space-between row>
          <v-flex>
            <v-btn class="text-sm-left" @click="calPrev()">
              <v-icon
                dark
                left
              >
                keyboard_arrow_left
              </v-icon>
              Prev
            </v-btn>
            </v-flex>
            <v-flex sm2>
              <v-select
                clearable
                :items="itemsList"
                v-model="filterCalendar"
                placeholder="Todos"
                label="Usuario"
                v-on:click:clear="clearx = true"
              ></v-select>
            </v-flex>
            <v-flex>
              <v-layout align-center justify-center row>
                <v-flex><h3> {{ this.month }} </h3></v-flex>
                <v-flex><v-dialog persistent v-model="dialog" max-height="700px" max-width="1200px">
                <template v-slot:activator="{ on }">
                  <v-btn fab color="blue" v-on="on"><v-icon>add</v-icon></v-btn>
                </template>
              <form method="POST" @submit.prevent="handleSubmit">
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 sm6 md4>
                          <v-text-field v-model="editedItem.numeroDeCaso" label="Numero de Caso*" required></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                          <v-select
                            required
                            :items="itemsList"
                            v-model="editedItem.usuario"
                            label="Usuario"
                          ></v-select>
                        </v-flex><v-flex xs12 sm6 md4>
                          <v-text-field v-model="editedItem.titulo" label="Titulo" required></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                          <v-text-field v-model="editedItem.nombreAsegurado" label="Nombre de Asegurado*" required></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                          <v-text-field v-model="editedItem.nombreDeContacto" label="Nombre de Contacto"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                          <v-text-field v-model="editedItem.telefono" label="Telefono*" required></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                          <v-text-field v-model="editedItem.direccion" label="Direccion*" required></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                          <v-text-field v-model="editedItem.comuna" label="Comuna*" required></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                          <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            full-width
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="computedDateFormatted"
                                label="Fecha"
                                prepend-icon="event"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker first-day-of-week="1" locale="es-la" v-model="editedItem.date" @change="menu = false" no-title>
                            </v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                          <v-text-field v-model="editedItem.observacion" label="Observación"></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  <div class="body-2 text-lg-right red--text">*Campo Requerido</div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" flat @click="close">Cancelar</v-btn>
                    <v-btn color="blue darken-1" type="submit" flat>Guardar</v-btn>
                  </v-card-actions>
                  </v-card>
                </form>
              </v-dialog>
              </v-flex>
              </v-layout>
            </v-flex>
            <v-flex>
            <v-btn class="text-sm-right" @click="calNext()">
              Next
              <v-icon
                right
                dark
              >
                keyboard_arrow_right
              </v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-img v-if="!calendarLoad" class="mt-5" :src="require('@/assets/icons/loading.svg')" contain max-height=200></v-img>
        <v-sheet v-else-if="calendarLoad" height="500">
          <v-calendar
            ref="calendar"
            v-model="today"
            :value="today"
            locale="es-cl"
            color="primary"
            :weekdays="[ 1, 2, 3, 4, 5, 6, 0]"
          >
            <template v-slot:day="{ date }">
              <template v-for="event in eventsMap[date]">
                <v-menu
                  :key="event.title"
                  v-model="event.open"
                  persistent
                  full-width
                  offset-x
                >
                  <template v-slot:activator="{ on }">
                    <v-container
                      v-if="!event.time"
                      v-ripple
                      :class="event.estado == false ? 'my-event' : 'my-event-2'"
                      v-on="on"
                      v-html="event.title"
                    ></v-container>
                  </template>
                  <v-card
                    :color="event.estado == false ? 'theme--light' : 'grey lighten-4'"
                    min-width="150px"
                    max-width="350px"
                    flat
                  >
                    <v-toolbar
                      color="primary"
                      dark
                    >
                      <v-toolbar-title v-html="event.title"></v-toolbar-title>
                      <v-spacer></v-spacer>
                      <!--v-btn icon>
                        <v-icon>favorite</v-icon>
                      </v-btn>
                      <v-btn icon @click="editItem(event)">
                        <v-icon>edit</v-icon>
                      </v-btn-->
                      <v-btn icon @click="eliminar(event)">
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-title primary-title>
                      <ul>
                        <li>Numero de Caso: <b>{{ event.numeroDeCaso }}</b></li>
                        <li>Nombre Asegurado: <b>{{ event.details[0] }}</b></li>
                        <li v-if="event.details[1]">Nombre Contacto: <b>{{ event.details[1] }}</b></li>
                        <li>Teléfono: <b>{{ event.details[2] }}</b></li>
                        <li>Dirección: <b>{{ event.details[3] }}, {{ event.details[4] }}</b></li>
                        <li v-if="event.details[5]">Observaciones: <b>{{ event.details[5] }}</b></li>
                      </ul>
                    </v-card-title>
                    <v-card-actions>
                      <v-btn
                        flat
                        color="fmcolor"
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </template>
            </template>
          </v-calendar>
        </v-sheet>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'agenda',
  data: () => ({
    dialog: false,
    menu: false,
    clearx: false,
    calendarLoad: false,
    editedIndex: -1,
    filterCalendar: '',
    itemsList: ['Andrea', 'Diego', 'Franco'],
    editedItem: {
      numeroDeCaso: undefined,
      usuario: undefined,
      titulo: undefined,
      nombreAsegurado: undefined,
      nombreDeContacto: undefined,
      telefono: undefined,
      direccion: undefined,
      comuna: undefined,
      observacion: undefined,
      date: undefined
    },
    defaultItem: {
      numeroDeCaso: undefined,
      usuario: undefined,
      titulo: undefined,
      nombreAsegurado: undefined,
      nombreDeContacto: undefined,
      telefono: undefined,
      direccion: undefined,
      comuna: undefined,
      observacion: undefined,
      date: undefined
    },
    type: 'month',
    month: '',
    monthInt: undefined,
    months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    today: '',
    end: '2100-01-01'
  }),
  watch: {
    dialog (val) {
      return val || this.close()
    }
    /* filterCalendar (value) {
      if (value === null || value === '') {

      } else if (value === 'Andrea') {

      } else if (value === 'Franco') {

      } else {

      }
    } */
  },
  computed: {
    ...mapState({
      events: state => state.eventos.all
    }),
    // convert the list of events into a map of lists keyed by date
    eventsMap () {
      const map = {}
      if (this.filterCalendar === null || this.filterCalendar === '') {
        this.events.items.forEach(e => (map[e.date] = map[e.date] || []).push(e))
        return map
      } else if (this.clearx === true) {
        this.events.items.forEach(e => (map[e.date] = map[e.date] || []).push(e))
        return map
      } else {
        const l = this.events.items.filter(item => {
          return item.usuario.includes(this.filterCalendar)
        })
        l.forEach(e => (map[e.date] = map[e.date] || []).push(e))
        return map
      }
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Evento' : 'Editar Evento'
    },
    computedDateFormatted () {
      return this.formatDate(this.editedItem.date)
    },
    filterEvents () {
      return this.events.items.filter(item => {
        return item.usuario.includes(this.filterCalendar)
      })
    }
  },
  methods: {
    ...mapActions('eventos', {
      create: 'creaEvento',
      getAllEventos: 'getAll',
      updateEvent: 'updeitEvento',
      deleteEvent: 'dilitEvento'
    }),
    open (event) {
      alert(event.title)
    },
    handleSubmit () {
      var evento = {
        numeroDeCaso: this.editedItem.numeroDeCaso,
        usuario: this.editedItem.usuario,
        title: this.editedItem.titulo,
        details: [this.editedItem.nombreAsegurado, this.editedItem.nombreDeContacto, this.editedItem.telefono, this.editedItem.direccion, this.editedItem.comuna, this.editedItem.observacion],
        date: this.editedItem.date
      }
      this.create(evento)
      this.close()
    },
    editItem (item) {
      this.editedIndex = this.events.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    formatDateEvent (dates) {
      if (!dates) return null
      dates.forEach(e => {
        e.date = e.date.substring(0, 10)
        const [year, month, day] = e.date.split('-')
        e.date = `${year}-${month}-${day}`
      })
    },
    formatDate (date) {
      if (!date) return null

      date = date.substring(0, 10)
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    calPrev () {
      if (this.monthInt === 0) {
        this.monthInt = 11
      } else {
        this.monthInt--
      }
      this.month = this.months[this.monthInt]
      this.$refs.calendar.prev()
    },
    calNext () {
      if (this.monthInt === 11) {
        this.monthInt = 0
      } else {
        this.monthInt++
      }
      this.month = this.months[this.monthInt]
      this.$refs.calendar.next()
    },
    terminar (evento) {
      var sure = confirm('Esta seguro que desea terminar ' + evento.title + ' para el caso n°' + evento.numeroDeCaso)
      if (sure === true) {
        evento.estado = !evento.estado
        evento.open = false
        this.updateEvent(evento)
        alert('Evento Terminado')
      } else {
        alert('No se termino el Evento')
      }
    },
    eliminar (evento) {
      var sure = confirm('Esta seguro que desea eliminar ' + evento.title + ' para el caso n°' + evento.numeroDeCaso)
      if (sure === true) {
        this.deleteEvent(evento.id)
        alert('Evento Eliminado')
      } else {
        alert('No se elimino el Evento')
      }
    },
    setDate () {
      var d = new Date()
      // const days = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo']
      this.monthInt = d.getMonth()
      this.month = this.months[d.getMonth()]
      this.today = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
    }
  },
  created () {
    this.setDate()
    this.formatDateEvent(this.events.items)
  },
  mounted () {
    setTimeout(() => {
      if (this.events.items) {
        this.calendarLoad = true
      }
    }, 1500)
  },
  updated () {
    this.formatDateEvent(this.events.items)
    setTimeout(() => {
      if (this.events.items) {
        this.calendarLoad = true
      }
    }, 1500)
  }
}
</script>

<style scoped>
  .my-event {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 2px;
    background-color: #1867c0;
    color: #ffffff;
    border: 1px solid #1867c0;
    width: 100%;
    font-size: 12px;
    padding: 3px;
    cursor: pointer;
    margin-bottom: 1px;
  }
  .my-event-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 2px;
    background-color: #b90504;
    color: #ffffff;
    border: 1px solid #b90504;
    width: 100%;
    font-size: 12px;
    padding: 3px;
    cursor: pointer;
    margin-bottom: 1px;
  }
</style>
