<template>
  <v-container>
    <v-toolbar flat color="white">
      <v-toolbar-title>Casos </v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-btn @click="exportXLSX" color="primary" icon dark><v-icon>archive</v-icon></v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Busqueda"
        single-line
        hide-details
      ></v-text-field>
    </v-toolbar>
    <v-data-table
      rows-per-page-text="Casos por pagina"
      v-model="selected"
      select-all
      ref="dataTable"
      :headers="headers"
      :items="this.casos.items"
      :search="search"
      class="elevation-1"
      disable-initial-sort
    >
      <template v-slot:items="caso" @dblclick="editItem(caso.item)">
        <tr :style="{ 'background-color': (caso.item.estado == 'En Ejecución' ? '#FFF176' : 'transparent' ) }">
        <td>
          <v-checkbox
            v-model="caso.selected"
            color="fmcolor"
            primary
            hide-details
          ></v-checkbox>
        </td>
        <td @dblclick="editItem(caso.item)">{{ caso.item.numeroDeCaso }}</td>
        <td :style="{backgroundColor: (caso.item.informeFinal != undefined ? '#ad2323' : 'transparent') }" class="" @dblclick="editItem(caso.item)"><a :href="caso.item.informeInicial"><v-icon>picture_as_pdf</v-icon></a></td>
        <td :style="{backgroundColor: (caso.item.informeFinal != undefined ? '#ad2323' : 'transparent') }" class="" @dblclick="editItem(caso.item)"><a :href="caso.item.informeFinal"><v-icon>picture_as_pdf</v-icon></a></td>
        <td class="px-0">
        <v-icon
            class="mr-2"
            @click="editItem(caso.item)"
          >
            edit
          </v-icon>
          <v-icon
            class="mr-2"
            v-on:click="deleteThis(caso.item)"
          >
            delete
        </v-icon>
        </td>
        <td class="text-xs-right" @dblclick="editItem(caso.item)">{{ caso.item.sucursal }}</td>
        <td class="text-xs-right" @dblclick="editItem(caso.item)">{{ caso.item.origen }}</td>
        <td class="text-xs-right" @dblclick="editItem(caso.item)">{{ caso.item.nombreAsegurado }}</td>
        <td class="text-xs-right" @dblclick="editItem(caso.item)">{{ caso.item.email }}</td>
        <td class="text-xs-right" @dblclick="editItem(caso.item)">{{ caso.item.telefono1 }}</td>
        <td class="text-xs-right" @dblclick="editItem(caso.item)">{{ caso.item.direccion }}</td>
        <td class="text-xs-right" @dblclick="editItem(caso.item)">{{ caso.item.comuna }}</td>
        <td class="text-xs-right" @dblclick="editItem(caso.item)">{{ caso.item.estado }}</td>
        <td class="text-xs-right" @dblclick="editItem(caso.item)">{{ formatDate(caso.item.fechaAsignacion) }}</td>
        <td class="text-xs-right" @dblclick="editItem(caso.item)">{{ caso.item.observacion }}</td>
        </tr>
      </template>
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="warning">
          No hay resultados para: "{{ search }}".
        </v-alert>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import XLSX from 'xlsx'

export default {
  data: () => ({
    search: '',
    selected: [],
    headers: [
      {
        text: 'Número de Caso',
        align: 'left',
        sortable: true,
        value: 'numeroDeCaso'
      },
      { text: 'Inf. Inicial', value: 'informeInicial', sortable: false },
      { text: 'Inf. Final', value: 'informeFinal', sortable: false },
      { text: 'Acciones', value: 'name', sortable: false },
      { text: 'Sucursal', value: 'sucursal' },
      { text: 'Origen', value: 'origen' },
      { text: 'Nombre Asegurado', value: 'nombreAsegurado' },
      { text: 'Email', value: 'email' },
      { text: 'Telefono 1', value: 'telefono1' },
      { text: 'Direccion', value: 'direccion' },
      { text: 'Comuna', value: 'comuna' },
      { text: 'Estado', value: 'estado' },
      { text: 'Fecha Asignación', value: 'fechaAsignacion' },
      { text: 'Observación', value: 'observacion' }
    ]
  }),
  computed: {
    ...mapState({
      casos: state => state.casos.all
    })
  },
  mounted () {
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      date = date.substring(0, 10)
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    exportXLSX () {
      if (this.selected.length > 1) {
        for (var i = 0, len = this.selected.length; i < len; i++) {
          delete this.selected[i]._id
          delete this.selected[i].id
          delete this.selected[i].__v
          delete this.selected[i].createdAt
          delete this.selected[i].updatedAt
        }
        const data = XLSX.utils.json_to_sheet(this.selected,
          {
            header: ['numeroDeCaso', 'nombreAsegurado', 'nombreDeContacto', 'email', 'telefono1', 'telefono2', 'direccion', 'comuna', 'estado', 'sucursal', 'origen', 'observacion']
          })
        data.A1.v = 'Numero de Caso'
        data.B1.v = 'Nombre de Asegurado'
        data.C1.v = 'Nombre de Contacto'
        data.D1.v = 'E-mail'
        data.E1.v = 'Telefono1'
        data.F1.v = 'Telefono2'
        data.G1.v = 'Direccion'
        data.H1.v = 'Comuna'
        data.I1.v = 'Estado'
        data.J1.v = 'Sucursal'
        data.K1.v = 'Origen'
        data.L1.v = 'Observacion'
        const workbook = XLSX.utils.book_new()
        const filename = 'fmsoluciones'
        XLSX.utils.book_append_sheet(workbook, data, filename)
        XLSX.writeFile(workbook, `${filename}.xlsx`)
      } else if (this.$refs.dataTable.filteredItems.length !== this.$refs.dataTable.itemsLength) {
        var sure = confirm(`Esta seguro que desea exportar los ${this.$refs.dataTable.filteredItems.length} casos de esta pagina?`)
        if (sure === true) {
          for (var z = 0, leng = this.$refs.dataTable.filteredItems.length; z < leng; z++) {
            delete this.$refs.dataTable.filteredItems[z]._id
            delete this.$refs.dataTable.filteredItems[z].id
            delete this.$refs.dataTable.filteredItems[z].__v
            delete this.$refs.dataTable.filteredItems[z].createdAt
            delete this.$refs.dataTable.filteredItems[z].updatedAt
          }
          const data = XLSX.utils.json_to_sheet(this.$refs.dataTable.filteredItems,
            {
              header: ['numeroDeCaso', 'nombreAsegurado', 'nombreDeContacto', 'email', 'telefono1', 'telefono2', 'direccion', 'comuna', 'estado', 'sucursal', 'origen', 'observacion']
            })
          data.A1.v = 'Numero de Caso'
          data.B1.v = 'Nombre de Asegurado'
          data.C1.v = 'Nombre de Contacto'
          data.D1.v = 'E-mail'
          data.E1.v = 'Telefono1'
          data.F1.v = 'Telefono2'
          data.G1.v = 'Direccion'
          data.H1.v = 'Comuna'
          data.I1.v = 'Estado'
          data.J1.v = 'Sucursal'
          data.K1.v = 'Origen'
          data.L1.v = 'Observacion'
          const workbook = XLSX.utils.book_new()
          const filename = 'fmsoluciones'
          XLSX.utils.book_append_sheet(workbook, data, filename)
          XLSX.writeFile(workbook, `${filename}.xlsx`)
          alert('Archivo Exportado')
        } else {
          alert('No se exporto nada')
        }
      } else if (this.$refs.dataTable.filteredItems.length === this.$refs.dataTable.itemsLength) {
        for (var y = 0, lengg = this.$refs.dataTable.filteredItems.length; y < lengg; y++) {
          delete this.$refs.dataTable.filteredItems[y]._id
          delete this.$refs.dataTable.filteredItems[y].id
          delete this.$refs.dataTable.filteredItems[y].__v
          delete this.$refs.dataTable.filteredItems[y].createdAt
          delete this.$refs.dataTable.filteredItems[y].updatedAt
        }
        const data = XLSX.utils.json_to_sheet(this.$refs.dataTable.filteredItems,
          {
            header: ['numeroDeCaso', 'nombreAsegurado', 'nombreDeContacto', 'email', 'telefono1', 'telefono2', 'direccion', 'comuna', 'estado', 'sucursal', 'origen', 'observacion']
          })
        data.A1.v = 'Numero de Caso'
        data.B1.v = 'Nombre de Asegurado'
        data.C1.v = 'Nombre de Contacto'
        data.D1.v = 'E-mail'
        data.E1.v = 'Telefono1'
        data.F1.v = 'Telefono2'
        data.G1.v = 'Direccion'
        data.H1.v = 'Comuna'
        data.I1.v = 'Estado'
        data.J1.v = 'Sucursal'
        data.K1.v = 'Origen'
        data.L1.v = 'Observacion'
        const workbook = XLSX.utils.book_new()
        const filename = 'fmsoluciones'
        XLSX.utils.book_append_sheet(workbook, data, filename)
        XLSX.writeFile(workbook, `${filename}.xlsx`)
      } else {
        for (var x = 0, lenng = this.casos.items.length; x < lenng; x++) {
          delete this.casos.items[x]._id
          delete this.casos.items[x].id
          delete this.casos.items[x].__v
          delete this.casos.items[x].createdAt
          delete this.casos.items[x].updatedAt
        }
        const data = XLSX.utils.json_to_sheet(this.casos.items,
          {
            header: ['numeroDeCaso', 'nombreAsegurado', 'nombreDeContacto', 'email', 'telefono1', 'telefono2', 'direccion', 'comuna', 'estado', 'sucursal', 'origen', 'observacion']
          })
        data.A1.v = 'Numero de Caso'
        data.B1.v = 'Nombre de Asegurado'
        data.C1.v = 'Nombre de Contacto'
        data.D1.v = 'E-mail'
        data.E1.v = 'Telefono1'
        data.F1.v = 'Telefono2'
        data.G1.v = 'Direccion'
        data.H1.v = 'Comuna'
        data.I1.v = 'Estado'
        data.J1.v = 'Sucursal'
        data.K1.v = 'Origen'
        data.L1.v = 'Observacion'
        const workbook = XLSX.utils.book_new()
        const filename = 'fmsoluciones'
        XLSX.utils.book_append_sheet(workbook, data, filename)
        XLSX.writeFile(workbook, `${filename}.xlsx`)
      }
    }
  }
}
</script>
<style>
  table.v-table tbody tr {
    max-width: 500px !important;
    max-height: 70px !important;
  }
</style>
