<template>
<section>
  <v-layout row wrap justify-center class="">
    <v-flex xs12 class="">
      <v-avatar
        size="164"
        class="elevation-4"
        color="#DDF0FE"
      ><v-img :src="`https://avataaars.io/?${value.avatar}`"></v-img>
      </v-avatar>
      <div class="font-weight-light headline my-2" v-text="value.name"/>
      <p>{{value.title}}</p>
      <a :href="`mailto:${value.email}`">
      <v-flex xs12 md12 class="layout justify-center">
      <v-icon color="fmcolor">email</v-icon>
      <div class="mx-3" v-text="value.email"/>
      </v-flex></a>
    </v-flex>
  </v-layout>
</section>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style>
.member h3 {
  font-size: 24px;
  font-weight: 500;
}
a {text-decoration: none;}
</style>
