<template>
  <v-container>
    <v-toolbar flat color="white">
      <v-toolbar-title>Maestros</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-dialog persistent full-screen v-model="edit">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark class="mb-2" v-on="on">Nuevo Registro</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex class="form-group" xs3>
                  <v-text-field v-model="editedItem.numeroDeCaso" label="Numero de Caso" name="numeroDeCaso" v-validate="'required'" class="form-control" :class="{ 'is-invalid': submitted && errors.has('numeroDeCaso') }" ></v-text-field>
                <div v-if="submitted && errors.has('numeroDeCaso')" class="invalid-feedback">{{ errors.first('numeroDeCaso') }}</div>
                </v-flex>
                <v-flex class="form-group" xs3>
                  <v-text-field v-model="editedItem.nombreMaestro" label="Nombre Maestro" name="nombreMaestro" v-validate="'required'" class="form-control" :class="{ 'is-invalid': submitted && errors.has('nombreMaestro') }" ></v-text-field>
                <div v-if="submitted && errors.has('nombreMaestro')" class="invalid-feedback">{{ errors.first('nombreMaestro') }}</div>
                </v-flex>
                <v-flex class="form-group" xs3>
                  <v-text-field v-model="editedItem.cliente" label="Cliente" type="cliente" name="cliente" v-validate="'required'" class="form-control" :class="{ 'is-invalid': submitted && errors.has('cliente') }" ></v-text-field>
                <div v-if="submitted && errors.has('cliente')" class="invalid-feedback">{{ errors.first('cliente') }}</div>
                </v-flex>
                <v-flex class="form-group" xs3>
                  <v-text-field v-model="editedItem.direccion" label="Direccion" name="direccion" v-validate="'required'" class="form-control" :class="{ 'is-invalid': submitted && errors.has('direccion') }" ></v-text-field>
                <div v-if="submitted && errors.has('direccion')" class="invalid-feedback">{{ errors.first('direccion') }}</div>
                </v-flex>
                <v-flex class="form-group" xs3>
                  <v-text-field v-model="editedItem.comuna" label="Comuna" name="comuna" v-validate="'required'" class="form-control" :class="{ 'is-invalid': submitted && errors.has('comuna') }" ></v-text-field>
                <div v-if="submitted && errors.has('comuna')" class="invalid-feedback">{{ errors.first('comuna') }}</div>
                </v-flex>
                <v-flex class="form-group" xs3>
                  <v-text-field v-model="editedItem.mes" label="Mes" name="mes" v-validate="'required'" class="form-control" :class="{ 'is-invalid': submitted && errors.has('mes') }" ></v-text-field>
                <div v-if="submitted && errors.has('mes')" class="invalid-feedback">{{ errors.first('mes') }}</div>
                </v-flex>
                <v-flex class="form-group" xs3>
                  <v-text-field v-model="editedItem.fecha" label="fecha" name="fecha" v-validate="'required'" class="form-control" :class="{ 'is-invalid': submitted && errors.has('fecha') }" ></v-text-field>
                <div v-if="submitted && errors.has('fecha')" class="invalid-feedback">{{ errors.first('fecha') }}</div>
                </v-flex>
                <v-flex class="form-group" xs3>
                  <v-text-field v-model="editedItem.detalle" label="detalle" name="detalle" v-validate="'required'" class="form-control" :class="{ 'is-invalid': submitted && errors.has('detalle') }" ></v-text-field>
                <div v-if="submitted && errors.has('detalle')" class="invalid-feedback">{{ errors.first('detalle') }}</div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" flat @click="close">Cancelar</v-btn>
            <v-btn color="blue darken-1" flat @click="handleSubmit">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <br>
    <v-card>
      <v-card-text>
        <v-list two-line>
          <template>
            <div v-for="(maestro, index) in maestros.items" :key="maestro.id" @dblclick="editItem(maestro)">
              <v-list-tile>
                <v-list-tile-avatar>
                  <v-icon>description</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{maestro.cliente}}</v-list-tile-title>
                  <v-list-tile-sub-title>{{maestro.direccion + ', ' + maestro.comuna}}</v-list-tile-sub-title>
                  <v-list-tile-sub-title>{{'Caso N°' + maestro.numeroDeCaso + ', Encargado: ' + maestro.nombreMaestro }}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action @click="editItem(maestro)">
                  <v-icon>edit</v-icon>
                </v-list-tile-action>
                <v-list-tile-action @click="deleteThis(maestro.id)">
                  <v-icon>delete</v-icon>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider v-if="index != maestros.items.length - 1"/>
            </div>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'maestros',
  data () {
    return {
      editedIndex: -1,
      editedItem: {
        numeroDeCaso: undefined,
        nombreMaestro: undefined,
        cliente: undefined,
        direccion: undefined,
        comuna: undefined,
        mes: undefined,
        fecha: undefined,
        detalle: undefined
      },
      defaultItem: {
        numeroDeCaso: undefined,
        nombreMaestro: undefined,
        cliente: undefined,
        direccion: undefined,
        comuna: undefined,
        mes: undefined,
        fecha: undefined,
        detalle: undefined
      },
      submitted: false,
      sure: false,
      edit: false
    }
  },
  computed: {
    ...mapState({
      maestros: state => state.maestros.all
    }),
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro'
    }
  },
  watch: {
    edit (val) {
      return val || this.close()
    }
  },
  methods: {
    pronto () {
      alert('Pronto descargar PDF')
    },
    prontoo () {
      alert('Pronto enviar PDF email')
    },
    ...mapActions('maestros', {
      create: 'creaMaestro',
      getAllMaestros: 'getAll',
      updateMaestro: 'updeitMaestro',
      deleteMaestro: 'dilitMaestro'
    }),
    handleSubmit (e) {
      this.submitted = true
      this.$validator.validate().then(valid => {
        if (valid) {
          var maestro = {
            id: this.editedItem.id,
            numeroDeCaso: this.editedItem.numeroDeCaso,
            nombreMaestro: this.editedItem.nombreMaestro,
            cliente: this.editedItem.cliente,
            direccion: this.editedItem.direccion,
            comuna: this.editedItem.comuna,
            mes: this.editedItem.mes,
            fecha: this.editedItem.fecha,
            detalle: this.editedItem.detalle
          }
          if (this.editedIndex === -1) {
            this.create(maestro)
            this.close()
          } else {
            this.updateMaestro(maestro)
            this.close()
          }
        }
      })
    },
    deleteThis (id) {
      var sure = confirm('Esta seguro que desea eliminar el Registro')
      if (sure === true) {
        this.deleteMaestro(id)
        alert('Registro Eliminado')
      } else {
        alert('No se elimino el Registro')
      }
    },
    close () {
      this.edit = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    editItem (item) {
      this.editedIndex = this.maestros.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.edit = true
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
</style>
