<template>
  <div>
    <Navbar/>
    <router-view/>
  </div>
</template>

<script>
import Navbar from '@/views/components/sucursal/Navbar.vue'

export default {
  name: 'sucursal',
  components: {
    Navbar
  },
  beforeCreate () {
    document.title = 'FM Soluciones | Sucursal'
  }
}
</script>

<style>
  .v-list__tile__action .v-icon {
    cursor: pointer;
  }
</style>
