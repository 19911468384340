<template>
  <v-container>
    <v-toolbar flat color="white">
      <v-toolbar-title>Usuarios</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-dialog persistent v-model="edit" max-width="600px">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark class="mb-2" v-on="on">Nuevo Usuario</v-btn>
        </template>
        <form method="POST" @submit.prevent="handleSubmit">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex class="form-group" xs12>
                  <v-text-field v-model="editedItem.email" label="Email" type="email" name="email" v-validate="'required'" class="form-control" :class="{ 'is-invalid': submitted && errors.has('email') }" ></v-text-field>
                <div v-if="submitted && errors.has('email')" class="invalid-feedback">{{ errors.first('email') }}</div>
                </v-flex>
                <v-flex class="form-group" xs12>
                  <v-text-field v-model="editedItem.password" label="Password" type="password" v-validate="{ required: true, min: 6 }" name="password" class="form-control" :class="{ 'is-invalid': submitted && errors.has('password') }"></v-text-field>
                <div v-if="submitted && errors.has('password')" class="invalid-feedback">{{ errors.first('password') }}</div>
                </v-flex>
                <v-flex class="form-group" xs12>
                  <v-select :items="roles" label="Rol" v-model="editedItem.role" required></v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" flat @click="close">Cancelar</v-btn>
            <v-btn color="blue darken-1" flat type="submit">Guardar</v-btn>
          </v-card-actions>
        </v-card>
        </form>
      </v-dialog>
    </v-toolbar>
    <br>
    <v-card>
      <v-card-text>
        <v-list two-line>
          <template>
            <div v-for="(user, index) in users.items" :key="user.id" @dblclick="editItem(user)">
              <v-list-tile>
                <v-list-tile-avatar>
                  <v-icon>person</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{user.email}}</v-list-tile-title>
                  <v-list-tile-sub-title>{{user.role}}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action @click="editItem(user)">
                  <v-icon>edit</v-icon>
                </v-list-tile-action>
                <v-list-tile-action @click="deleteThis(user.id)">
                  <v-icon>delete</v-icon>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider v-if="index != users.items.length - 1"/>
            </div>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'users',
  data () {
    return {
      roles: ['Manager', 'Sucursal', 'Administrador'],
      editedIndex: -1,
      editedItem: {
        email: undefined,
        password: undefined,
        role: undefined
      },
      defaultItem: {
        email: undefined,
        password: undefined,
        role: undefined
      },
      submitted: false,
      sure: false,
      edit: false
    }
  },
  computed: {
    ...mapState({
      account: state => state.account,
      users: state => state.users.all
    }),
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Usuario' : 'Editar Usuario'
    }
  },
  watch: {
    edit (val) {
      return val || this.close()
    }
  },
  methods: {
    ...mapActions('users', {
      create: 'register',
      getAllUsers: 'getAll',
      updateUser: 'update',
      deleteUser: 'delete'
    }),
    handleSubmit (e) {
      this.submitted = true
      this.$validator.validate().then(valid => {
        if (valid) {
          var user = {
            id: this.editedItem.id,
            email: this.editedItem.email,
            password: this.editedItem.password,
            role: this.editedItem.role
          }
          if (this.editedIndex === -1) {
            this.create(user)
            this.close()
          } else {
            this.updateUser(user)
            this.close()
          }
        }
      })
    },
    deleteThis (id) {
      var sure = confirm('Esta seguro que desea eliminar el Usuario')
      if (sure === true) {
        this.deleteUser(id)
        alert('Usuario Eliminado')
      } else {
        alert('No se elimino el Usuario')
      }
    },
    close () {
      this.edit = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    editItem (item) {
      this.editedIndex = this.users.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.edit = true
    }
  }
}
</script>
